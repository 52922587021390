import { createItem, deleteItems, readItem, readItems, updateItem } from '@directus/sdk';

import directus, { MutationQuery, RequestQuery } from 'config/directus';
import api from 'services/api';
import { ResponsibleFunction } from 'shared/model/types';

export const extendedApi = api
  .enhanceEndpoints({
    addTagTypes: ['responsibleFunction', 'responsibleFunctions'],
  })
  .injectEndpoints({
    overrideExisting: true,
    endpoints: builder => ({
      getResponsibleFunctions: builder.query<
        ResponsibleFunction[],
        RequestQuery<ResponsibleFunction>
      >({
        queryFn: async q => {
          const data = await directus.request<ResponsibleFunction[]>(
            readItems('responsibleFunction', q),
          );
          return { data };
        },
        providesTags: ['responsibleFunctions'],
      }),
      getResponsibleFunction: builder.query<
        ResponsibleFunction,
        { id: string; q?: RequestQuery<ResponsibleFunction> }
      >({
        queryFn: async q => {
          const data = await directus.request<ResponsibleFunction>(
            readItem('responsibleFunction', q.id, q.q),
          );
          return { data };
        },
        providesTags: ['responsibleFunction'],
      }),
      createResponsibleFunction: builder.mutation<
        ResponsibleFunction,
        {
          file: File;
          item: Partial<ResponsibleFunction>;
          query?: MutationQuery<ResponsibleFunction>;
        }
      >({
        queryFn: async q => {
          const data = await directus.request<ResponsibleFunction>(
            createItem('responsibleFunction', q.item, q.query),
          );
          return { data };
        },
        invalidatesTags: ['responsibleFunction', 'responsibleFunctions'],
      }),
      updateResponsibleFunction: builder.mutation<
        ResponsibleFunction,
        {
          id: string;
          item: Partial<ResponsibleFunction>;
          query?: MutationQuery<ResponsibleFunction>;
        }
      >({
        queryFn: async q => {
          const data = await directus.request<ResponsibleFunction>(
            updateItem('responsibleFunction', q.id, q.item, q.query),
          );
          return { data };
        },
        invalidatesTags: ['responsibleFunction', 'responsibleFunctions'],
      }),
      deleteResponsibleFunction: builder.mutation<void, ResponsibleFunction[]>({
        queryFn: async responsibleFunction => {
          const ids = responsibleFunction.map(item => item.id);
          await directus.request(deleteItems('responsibleFunction', ids));

          return {
            data: undefined,
          };
        },
        invalidatesTags: ['responsibleFunction', 'responsibleFunctions'],
      }),
    }),
  });

export const {
  useGetResponsibleFunctionsQuery,
  useGetResponsibleFunctionQuery,
  useCreateResponsibleFunctionMutation,
  useUpdateResponsibleFunctionMutation,
  useDeleteResponsibleFunctionMutation,
} = extendedApi;

import { DragDropContext, Droppable, DropResult } from '@hello-pangea/dnd';
import BlockIcon from '@mui/icons-material/Block';
import CheckIcon from '@mui/icons-material/Check';
import { Box, Button, List, Paper } from '@mui/material';
import isNumber from 'lodash/isNumber';
import isObject from 'lodash/isObject';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import IconValue from 'shared/components/IconValue';
import TutelleDraggableItem from 'shared/components/tutelles/TutelleDraggableItem';
import TutelleForm from 'shared/components/tutelles/TutelleForm';
import { TutelleIcon } from 'shared/model/def/Tutelle.def';
import { Organization, Tutelle } from 'shared/model/types';
import { closeAllDrawers } from 'shared/reducers/drawerSlice';
import { useAppDispatch } from 'shared/redux/redux-utils';
import { reorder } from 'shared/utils/array-utils';

const defaultSelection = {
  organization: null,
  code: '',
};

type Props = {
  tutelles: Tutelle[];
  onConfirm: (
    toAddItems: Partial<Tutelle>[],
    toUpdateItems: Partial<Tutelle>[],
    toDeleteItems: Tutelle[],
  ) => Promise<void>;
  isDisabled: boolean;
};

const TutellesSelection = ({ tutelles, isDisabled, onConfirm }: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [orderedList, setOrderedList] = useState<Partial<Tutelle>[]>([...tutelles]);

  const [selectedTutelle, setSelectedTutelle] = useState<Partial<Tutelle>>(defaultSelection);

  const onConfirmClicked = async () => {
    const toDeleteItems = tutelles.filter(
      tutelle => !orderedList.some(item => item.id === tutelle.id),
    );
    const toAddItems = orderedList.filter(tutelle => tutelle.id === undefined);

    await onConfirm(toAddItems, orderedList, toDeleteItems);

    dispatch(closeAllDrawers());
  };

  const onCancel = () => {
    dispatch(closeAllDrawers());
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const newOrder = reorder<Partial<Tutelle>>(
      orderedList,
      result.source.index,
      result.destination.index,
    ).map((field, index) => ({
      ...field,
      sort: index,
    }));
    setOrderedList(newOrder);
  };

  const onEditItem = (item: Partial<Tutelle>) => {
    setSelectedTutelle(item);
  };

  const onDeleteItem = (item: Partial<Tutelle>) => {
    const newOrder = orderedList.filter(field => {
      if (isNumber(field.id)) {
        return field.id !== item.id;
      }
      if (isObject(field.organization) && isObject(item.organization)) {
        return field.organization.id !== item.organization.id;
      }
      return true;
    });
    setOrderedList(newOrder);
    setSelectedTutelle(defaultSelection);
  };

  const onFormSubmit = (newOrganization: Organization, newCode: string) => {
    //si l'organisation sélectionnée est déjà dans la liste
    const found = orderedList.find(
      item => isObject(item.organization) && item.organization.id === newOrganization.id,
    );

    if (found) {
      const newTutelle = {
        ...found,
        code: newCode,
      };
      const newOrder = orderedList.map(item => {
        if (isObject(item.organization) && item.organization.id === newOrganization.id) {
          return newTutelle;
        }
        return item;
      });
      setOrderedList(newOrder);
      setSelectedTutelle(newTutelle);
    } else {
      //ajout de la nouvelle tutelle
      const newTutelle = {
        organization: newOrganization,
        code: newCode,
        sort: orderedList.length,
      };
      setOrderedList([...orderedList, newTutelle]);
      setSelectedTutelle(newTutelle);
    }
  };

  return (
    <Box p={2}>
      <Box display="flex" justifyContent="space-between">
        <IconValue icon={<TutelleIcon />}>
          <Box>{t('tutelles.manage')}</Box>
        </IconValue>
        <Box>
          <Button size="small" onClick={onConfirmClicked} color="success" disabled={isDisabled}>
            <CheckIcon />
          </Button>
          <Button
            size="small"
            onClick={onCancel}
            variant="outlined"
            sx={{ ml: 1 }}
            disabled={isDisabled}
          >
            <BlockIcon />
          </Button>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        height="calc(100vh - 100px)"
      >
        <Box>
          {orderedList.length === 0 && (
            <Box textAlign="center" mt={4}>
              {t('noResult')}
            </Box>
          )}
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="field-list">
              {provided => (
                <List dense ref={provided.innerRef} {...provided.droppableProps}>
                  {orderedList.map((item, index) => (
                    <TutelleDraggableItem
                      key={item.id ?? (item.organization as Organization)?.id ?? index}
                      item={item}
                      provided={provided}
                      index={index}
                      onDelete={onDeleteItem}
                      onEdit={onEditItem}
                    />
                  ))}
                  {provided.placeholder}
                </List>
              )}
            </Droppable>
          </DragDropContext>
        </Box>
        <Box>
          <Paper elevation={4}>
            <Box p={2}>
              <TutelleForm
                selectedOrganization={selectedTutelle.organization as Organization | null}
                selectedCode={selectedTutelle.code as string}
                onSubmit={onFormSubmit}
              />
            </Box>
          </Paper>
        </Box>
      </Box>
    </Box>
  );
};

export default TutellesSelection;

import Box from '@mui/material/Box/Box';
import Typography from '@mui/material/Typography/Typography';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  i18n?: string;
  text?: string;
  icon?: ReactNode;
  endIcon?: ReactNode;
  className?: string;
  variant?: 'h6' | 'h5' | 'h4' | 'h3' | 'h2' | 'h1';
}

const PageTitle = ({ i18n, icon, text, className, variant = 'h6', endIcon }: Props) => {
  const { t } = useTranslation();

  const finalText = i18n ? t(i18n) : (text ?? '');

  return (
    <Typography
      variant={variant}
      display="flex"
      alignItems="center"
      flexWrap="wrap"
      mb={1}
      className={className}
    >
      {icon}
      <Box marginLeft={icon ? 1 : 0} marginRight={endIcon ? 1 : 0}>
        {finalText}
      </Box>
      {endIcon}
    </Typography>
  );
};

export default PageTitle;

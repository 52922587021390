import ConfirmInvite from 'modules/user/confirmInvite/ConfirmInvite';
import LoginPage from 'modules/user/login/LoginPage';
import RequestResetPwdPage from 'modules/user/resetPwd/RequestResetPwdPage';
import ResetPwdPage from 'modules/user/resetPwd/ResetPwdPage';
import { RouteObject } from 'react-router-dom';

const routes: RouteObject[] = [
  {
    path: '/login',
    element: <LoginPage />,
  },
  {
    path: '/request-reset-password',
    element: <RequestResetPwdPage />,
  },
  {
    path: '/reset-password',
    element: <ResetPwdPage />,
  },
  {
    path: '/confirm-invite',
    element: <ConfirmInvite />,
  },
];

export default routes;

import { Autocomplete, Box, TextField } from '@mui/material';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { useState } from 'react';
import { SyntheticEvent } from 'react-draft-wysiwyg';
import { useTranslation } from 'react-i18next';
import { useGetResponsibleFunctionsQuery } from 'services/endpoints/ResponsibleFunctionEndpoints';
import { ResponsibleFunction } from 'shared/model/types';

type Props = {
  value: ResponsibleFunction | null;
  onChange: (newValue: ResponsibleFunction | null) => void;
  error?: boolean;
  helperText?: string;
  disabled?: boolean;
};

const ResponsibleFunctionAutocomplete = ({
  value,
  onChange,
  error,
  helperText,
  disabled,
}: Props) => {
  const { t } = useTranslation();

  const [inputValue, setInputValue] = useState('');

  const { data, isLoading } = useGetResponsibleFunctionsQuery({
    sort: ['name'],
    limit: 200,
  });

  const onSelection = (e: SyntheticEvent, value: ResponsibleFunction | null) => {
    onChange(value);
  };

  const metiers = data || [];

  return (
    <Autocomplete
      autoComplete
      value={value ?? null}
      getOptionLabel={opt => opt.name ?? ''}
      getOptionKey={opt => opt.id}
      renderInput={params => (
        <TextField
          {...params}
          label={t('team.responsibleFunction')}
          fullWidth
          disabled={disabled}
          error={error}
          helperText={helperText}
        />
      )}
      options={metiers}
      loading={isLoading}
      filterOptions={x => x}
      onChange={onSelection}
      includeInputInList
      noOptionsText={t('noResult')}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderOption={(props, option) => {
        const { key, ...optionProps } = props;

        const optionToSearch = option.name ?? '';

        const matches = match(optionToSearch, inputValue, { insideWords: true });
        const parts = parse(optionToSearch, matches);

        return (
          <li key={key} {...optionProps}>
            {parts.map((part, index) => (
              <Box
                key={index}
                component="span"
                sx={{
                  fontWeight: part.highlight ? 'bold' : 'regular',
                  whiteSpace: 'pre-wrap',
                }}
              >
                {part.text}
              </Box>
            ))}
          </li>
        );
      }}
    ></Autocomplete>
  );
};

export default ResponsibleFunctionAutocomplete;

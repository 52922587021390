import { Box, Grid2 as Grid } from '@mui/material';
import { useGetTeam } from 'modules/sheet/sheetHook';
import TeamScientificAttachmentsList from 'modules/sheet/team/TeamScientificAttachmentsList';
import TeamTutelleList from 'modules/sheet/team/TeamTutelleList';

const TeamMainTab = () => {
  const { data } = useGetTeam();

  if (!data) {
    return null;
  }

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid
          size={{
            xs: 12,
            lg: 6,
          }}
        >
          <TeamScientificAttachmentsList team={data} />
        </Grid>
        <Grid
          size={{
            xs: 12,
            lg: 6,
          }}
        >
          <TeamTutelleList team={data} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default TeamMainTab;

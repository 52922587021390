import { useTranslation } from 'react-i18next';
import PasswordStrengthBar, { PasswordFeedback } from 'react-password-strength-bar';

type Props = {
  password: string;
  minLength: number;
  onChangeScore: (score: number, feedback?: PasswordFeedback) => void;
};

const PasswordI18nStrengthBar = (props: Props) => {
  const { t } = useTranslation();
  const { password, minLength } = props;

  const scoreWords = [
    t('passwordStrength.weak'),
    t('passwordStrength.okay'),
    t('passwordStrength.good'),
    t('passwordStrength.strong'),
  ];

  const onChange = (score: number, feedback: PasswordFeedback) => {
    props.onChangeScore(score, feedback);
  };

  return (
    <PasswordStrengthBar
      password={password}
      scoreWords={scoreWords}
      shortScoreWord={t('passwordStrength.tooShort')}
      minLength={minLength}
      onChangeScore={onChange}
    />
  );
};

export default PasswordI18nStrengthBar;

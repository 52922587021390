import PersonMainTab from 'modules/sheet/person/PersonMainTab';
import PersonSheet from 'modules/sheet/person/PersonSheet';
import { Outlet, RouteObject } from 'react-router-dom';

const routes: RouteObject[] = [
  {
    path: 'person/:id',
    element: (
      <PersonSheet>
        <Outlet />
      </PersonSheet>
    ),
    children: [
      {
        path: 'main',
        element: <PersonMainTab />,
      },
      {
        path: 'follow',
        element: <PersonMainTab />,
      },
      {
        path: 'participations',
        element: <PersonMainTab />,
      },
    ],
  },
];

export default routes;

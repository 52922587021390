import { Draggable, DroppableProvided } from '@hello-pangea/dnd';
import DeleteIcon from '@mui/icons-material/Delete';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import EditIcon from '@mui/icons-material/Edit';
import { Box, IconButton, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { getTutelleItemName } from 'shared/components/tutelles/tutelleUtils';
import { Tutelle } from 'shared/model/types';

type Props = {
  item: Partial<Tutelle>;
  provided: DroppableProvided;
  index: number;
  onDelete: (item: Partial<Tutelle>) => void;
  onEdit: (item: Partial<Tutelle>) => void;
};

const TutelleDraggableItem = ({ item, provided, index, onDelete, onEdit }: Props) => {
  const str = getTutelleItemName(item);

  const onDeleteClicked = () => {
    onDelete(item);
  };

  const onEditClicked = () => {
    onEdit(item);
  };

  return (
    <Draggable draggableId={item.id + ''} index={index}>
      {provided => (
        <>
          <ListItem
            sx={{
              borderTop: index === 0 ? 1 : 0,
              borderBottom: 1,
              borderColor: 'divider',
            }}
            secondaryAction={
              <Box>
                <IconButton edge="end" onClick={onEditClicked} color="primary">
                  <EditIcon />
                </IconButton>
                <IconButton edge="end" onClick={onDeleteClicked} color="error">
                  <DeleteIcon />
                </IconButton>
              </Box>
            }
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <ListItemIcon>
              <DragIndicatorIcon />
            </ListItemIcon>
            <ListItemText primary={str} />
          </ListItem>
        </>
      )}
    </Draggable>
  );
};

export default TutelleDraggableItem;

import EmailIcon from '@mui/icons-material/Email';
import FaxIcon from '@mui/icons-material/Fax';
import PhoneIcon from '@mui/icons-material/Phone';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import { Box, Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import DisplayHtml from 'shared/components/DisplayHtml';
import IconValue from 'shared/components/IconValue';
import { ScientificAttachmentIcon } from 'shared/model/def/ScientificAttachment.def';
import { TeamIcon } from 'shared/model/def/team.def';
import {
  Person,
  PersonScientificAttachment,
  ScientificAttachment,
  Team,
  TeamMember,
} from 'shared/model/types';
import { disablePropagation } from 'shared/utils/event-utils';
import { stringHtmlHasContent } from 'shared/utils/string-utils';

type Props = {
  person: Person;
  noDescription?: boolean;
  noScientificAttachments?: boolean;
};

const SearchResultPerson = ({
  person,
  noDescription = false,
  noScientificAttachments = false,
}: Props) => {
  const teamMembers = person.teamMembers ? ([...person.teamMembers] as TeamMember[]) : [];
  teamMembers.sort((a, b) => {
    const s1 = a.sort || 0;
    const s2 = b.sort || 0;
    return s1 - s2;
  });

  const teamToShow = teamMembers.length > 0 ? (teamMembers[0].team as Team) : undefined;

  const scientificAttachmentLinks = (
    person.scientificAttachment && !noScientificAttachments ? person.scientificAttachment : []
  ) as PersonScientificAttachment[];
  const scientificAttachments = scientificAttachmentLinks.map(attachment => {
    return attachment.scientificAttachment_id as ScientificAttachment;
  });

  const md = noDescription ? 12 : 6;
  const lg = noDescription ? 12 : 4;
  const xl = noDescription ? 12 : 4;

  return (
    <Grid container alignItems="flex-start" spacing={1} className="">
      <Grid item xs={12} md={md} lg={lg} xl={xl}>
        {teamToShow && (
          <IconValue icon={<TeamIcon fontSize="small" />}>
            <Box onClick={disablePropagation}>
              <Link to={`/sheet/team/${teamToShow.id}/main`}>{teamToShow.name}</Link>
            </Box>
          </IconValue>
        )}
        {person.email && (
          <IconValue icon={<EmailIcon fontSize="small" />}>
            <Box onClick={disablePropagation}>
              <a href={'mailto:' + person.email}>{person.email}</a>
            </Box>
          </IconValue>
        )}
        {scientificAttachments.length > 0 && (
          <IconValue icon={<ScientificAttachmentIcon fontSize="small" />}>
            <Box>
              {scientificAttachments.map(s => (
                <Box key={s.id}>{s.name}</Box>
              ))}
            </Box>
          </IconValue>
        )}
        {person.phone && (
          <IconValue icon={<PhoneIcon fontSize="small" />}>
            <Box>{person.phone}</Box>
          </IconValue>
        )}
        {person.portable && (
          <IconValue icon={<SmartphoneIcon fontSize="small" />}>
            <Box>{person.portable}</Box>
          </IconValue>
        )}
        {person.fax && (
          <IconValue icon={<FaxIcon fontSize="small" />}>
            <Box>{person.fax}</Box>
          </IconValue>
        )}
      </Grid>
      {!noDescription && (
        <Grid item xs={12} md={6} lg={8} xl={8}>
          {person.description && stringHtmlHasContent(person.description) && (
            <DisplayHtml html={person.description} />
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default SearchResultPerson;

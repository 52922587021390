import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import { Structure } from 'shared/model/types';

export const StructureIcon = CorporateFareIcon;

export type StructureFormType = Omit<
  Structure,
  'id' | 'scientificAttachments' | 'tutelles' | 'jobs' | 'teams'
>;

export const getStructureAdress = (structure?: Structure) => {
  if (!structure) {
    return '';
  }

  let result = '';
  if (structure.address) {
    result += structure.address;
  }
  if (structure.address_2) {
    result += ' ' + structure.address_2;
  }
  if (structure.cp) {
    result += ' ' + structure.cp;
  }
  if (structure.city) {
    result += ' ' + structure.city;
  }
  return result;
};

import { createItem, deleteItems, readItem, readItems, updateItem } from '@directus/sdk';

import directus, { MutationQuery, RequestQuery } from 'config/directus';
import api from 'services/api';
import { Geosite } from 'shared/model/types';

export const extendedApi = api
  .enhanceEndpoints({
    addTagTypes: ['geosite', 'geosites'],
  })
  .injectEndpoints({
    overrideExisting: true,
    endpoints: builder => ({
      getGeosites: builder.query<Geosite[], RequestQuery<Geosite>>({
        queryFn: async q => {
          const data = await directus.request<Geosite[]>(readItems('geosite', q));
          return { data };
        },
        providesTags: ['geosites'],
      }),
      getGeosite: builder.query<Geosite, { id: string; q?: RequestQuery<Geosite> }>({
        queryFn: async q => {
          const data = await directus.request<Geosite>(readItem('geosite', q.id, q.q));
          return { data };
        },
        providesTags: ['geosite'],
      }),
      createGeosite: builder.mutation<
        Geosite,
        {
          file: File;
          item: Partial<Geosite>;
          query?: MutationQuery<Geosite>;
        }
      >({
        queryFn: async q => {
          const data = await directus.request<Geosite>(createItem('geosite', q.item, q.query));
          return { data };
        },
        invalidatesTags: ['geosite', 'geosites'],
      }),
      updateGeosite: builder.mutation<
        Geosite,
        {
          id: string;
          item: Partial<Geosite>;
          query?: MutationQuery<Geosite>;
        }
      >({
        queryFn: async q => {
          const data = await directus.request<Geosite>(
            updateItem('geosite', q.id, q.item, q.query),
          );
          return { data };
        },
        invalidatesTags: ['geosite', 'geosites'],
      }),
      deleteGeosite: builder.mutation<void, Geosite[]>({
        queryFn: async geosite => {
          const ids = geosite.map(item => item.id);
          await directus.request(deleteItems('geosite', ids));

          return {
            data: undefined,
          };
        },
        invalidatesTags: ['geosite', 'geosites'],
      }),
    }),
  });

export const {
  useGetGeositesQuery,
  useGetGeositeQuery,
  useCreateGeositeMutation,
  useUpdateGeositeMutation,
  useDeleteGeositeMutation,
} = extendedApi;

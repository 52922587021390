import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tab,
  Tabs,
} from '@mui/material';
import { SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TabContent from 'shared/components/tab/TabContent';
import { greek, latin, maths, specials } from 'shared/components/textEditor/charactersUtils';

const tabs = [
  {
    label: 'Grec',
    values: greek,
  },
  {
    label: 'Special',
    values: specials,
  },
  {
    label: 'Math',
    values: maths,
  },
  {
    label: 'Latin',
    values: latin,
  },
];

type Props = {
  open: boolean;
  onConfirm: (selection: string) => void;
  onCancel: () => void;
};

const SpecialCharacterDialog = ({ open, onCancel, onConfirm }: Props) => {
  const { t } = useTranslation();

  const [tab, setTab] = useState(0);

  const onClick = (e: string) => () => {
    onConfirm(e);
  };

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>{t('specialCharacters')}</DialogTitle>
      <DialogContent>
        <Box borderBottom={1} borderColor="divider">
          <Tabs value={tab} onChange={handleChange}>
            {tabs.map((tab, index) => (
              <Tab key={index} label={tab.label} />
            ))}
          </Tabs>
        </Box>
        <Box pt={1}>
          {tabs.map((item, index) => (
            <TabContent value={tab} index={index} key={item.label}>
              <Box
                sx={{
                  minHeight: '60vh',
                }}
              >
                <Box display="flex" flexWrap="wrap">
                  {item.values.map(char => (
                    <Box key={char.normal} mb={2} mr={1}>
                      {char.capital && (
                        <Box mb={0.5}>
                          <Button
                            variant="outlined"
                            color="inherit"
                            sx={{
                              textTransform: 'none',
                            }}
                            onClick={onClick(char.capital)}
                          >
                            <Box
                              dangerouslySetInnerHTML={{
                                __html: char.capital,
                              }}
                            ></Box>
                          </Button>
                        </Box>
                      )}
                      <Box>
                        <Button
                          variant="outlined"
                          color="inherit"
                          sx={{
                            textTransform: 'none',
                          }}
                          onClick={onClick(char.normal)}
                        >
                          <Box
                            dangerouslySetInnerHTML={{
                              __html: char.normal,
                            }}
                          ></Box>
                        </Button>
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Box>
            </TabContent>
          ))}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SpecialCharacterDialog;

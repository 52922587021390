import { useDeletePersonMutation } from 'services/endpoints/PersonEndpoints';
import ConfirmDelete from 'shared/components/confirmDelete/ConfirmDelete';

type Props = {
  ids: number[];
  onDeleted?: () => void;
};

const DeletePersonsBtn = ({ ids, onDeleted }: Props) => {
  const [deleteItem] = useDeletePersonMutation();

  const onConfirm = async () => {
    await deleteItem(ids.map(id => id.toString())).unwrap();
    if (onDeleted) {
      onDeleted();
    }
  };

  return <ConfirmDelete onlyIcon onConfirm={onConfirm} />;
};

export default DeletePersonsBtn;

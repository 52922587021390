import MuiPersonIcon from '@mui/icons-material/Person';
import { Person } from 'shared/model/types';

export const PersonIcon = MuiPersonIcon;

export type PersonFormType = Omit<
  Person,
  'id' | 'scientificAttachment' | 'teamMembers' | 'groupes' | 'jobs'
>;

export type CiviliteType = 'mr' | 'mme' | null;

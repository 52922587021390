import { zodResolver } from '@hookform/resolvers/zod';
import isNumber from 'lodash/isNumber';
import isObject from 'lodash/isObject';
import { useTranslation } from 'react-i18next';
import { JobFormType } from 'shared/model/def/job.def';
import { Metier, Person, Statut, Structure } from 'shared/model/types';
import { z, ZodType } from 'zod';

// Begin with https://transform.tools/typescript-to-zod

const useJobResolver = () => {
  const { t } = useTranslation();
  const requiredMessage = t('form.errors.required');

  const schema = z.object({
    label: z.string(),
    sort: z.number().int(),
    metier: z.custom<Metier>(value => isObject(value), { message: requiredMessage }),
    person: z.custom<Person>(value => isObject(value) || isNumber(value), {
      message: requiredMessage,
    }),
    statut: z.custom<Statut>(value => isObject(value), { message: requiredMessage }),
    structure: z.custom<Structure>(value => isObject(value) || isNumber(value), {
      message: requiredMessage,
    }),
    startDate: z.optional(z.any()),
    endDate: z.optional(z.any()),
  }) satisfies ZodType<JobFormType>;

  return zodResolver(schema);
};
export default useJobResolver;

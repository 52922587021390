import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/query/react';

export type DirectusErrorType = {
  errors: [
    {
      message: string;
      extensions: {
        code: string;
      };
    },
  ];
};

export const api = createApi({
  baseQuery: fakeBaseQuery<DirectusErrorType>(),
  endpoints: () => ({}),
  reducerPath: 'api',
});

export default api;

import { AggregationsAggregationContainer } from '@elastic/elasticsearch/lib/api/types';

export const teamFacets: Record<string, AggregationsAggregationContainer> = {
  _index: {
    terms: {
      field: '_index',
      size: 30,
    },
    aggs: {
      'scientific_attachment.scientificAttachment_id.name': {
        terms: {
          field: 'scientific_attachment.scientificAttachment_id.name.keyword',
          size: 30,
        },
      },
      'type.name': {
        terms: {
          field: 'type.name.keyword',
          size: 30,
        },
      },
      'geosite.name': {
        terms: {
          field: 'geosite.name.keyword',
          size: 30,
        },
      },
    },
  },
};

import Box from '@mui/material/Box';
import SearchResult from 'modules/search/results/SearchResult';
import { useGetLastSearches, useMakeSearchQuery } from 'modules/search/searchHook';
import SearchTextInput from 'modules/search/SearchTextInput';

import { SearchTotalHits } from '@elastic/elasticsearch/lib/api/types';
import { Grid2 as Grid } from '@mui/material';
import SearchNoResult from 'modules/search/results/SearchNoResult';
import RootSearchFacets from 'modules/search/RootSearchFacets';
import { useEffect } from 'react';
import { initWithLastSearch } from 'shared/reducers/searchSlice';
import { useAppDispatch } from 'shared/redux/redux-utils';
import './searchPage.scss';

const SearchPage = () => {
  const dispatch = useAppDispatch();
  const { data: searchesData, isSuccess: searchesIsSuccess } = useGetLastSearches();

  const { data, isFetching, isLoading, isSuccess } = useMakeSearchQuery();

  const totalHits = data?.hits.total as SearchTotalHits | undefined;
  const total = totalHits?.value ?? 0;

  const searches = searchesData ?? [];

  useEffect(() => {
    if (searchesIsSuccess && searches.length > 0) {
      dispatch(initWithLastSearch(searches[0].query));
    }
  }, [searchesIsSuccess]);

  return (
    <Box px={1}>
      <Box my={1} pl={1}>
        <SearchTextInput loading={isFetching} />
      </Box>
      {total === 0 && !isLoading && isSuccess ? (
        <SearchNoResult loading={isFetching} />
      ) : isSuccess ? (
        <Grid container spacing={1}>
          <Grid
            size={{
              xs: 12,
              md: 4,
              lg: 3,
              xl: 2,
            }}
          >
            <RootSearchFacets isLoading={isLoading} response={data} />
          </Grid>
          <Grid
            size={{
              xs: 12,
              md: 8,
              lg: 9,
              xl: 10,
            }}
          >
            <SearchResult isLoading={isLoading} response={data} />
          </Grid>
        </Grid>
      ) : null}
    </Box>
  );
};

export default SearchPage;

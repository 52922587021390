import Alert from '@mui/material/Alert/Alert';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar/Snackbar';
import { closeAlert } from 'shared/reducers/alertSlice';
import { useAppDispatch, useAppSelector } from 'shared/redux/redux-utils';

const AlertSnacks = () => {
  const alert = useAppSelector(state => state.snacks.alert);
  const dispatch = useAppDispatch();

  if (!alert) {
    return null;
  }

  return (
    <Snackbar
      key={alert.id}
      open={alert.open}
      autoHideDuration={6000}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      onClose={() => dispatch(closeAlert())}
    >
      <Alert severity={alert.severity}>
        <Box>{alert.text}</Box>
        {alert.details && <Box sx={{ fontSize: 'small' }}>{alert.details}</Box>}
      </Alert>
    </Snackbar>
  );
};

export default AlertSnacks;

import { QueryDslQueryContainer } from '@elastic/elasticsearch/lib/api/types';
import SearchIcon from '@mui/icons-material/Search';
import { Box, IconButton, TextField } from '@mui/material';
import cloneDeep from 'lodash/cloneDeep';
import { useGetSearchText } from 'modules/search/searchHook';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'react-use';
import Loader from 'shared/components/Loader';
import { setQuery } from 'shared/reducers/searchSlice';

import { useAppDispatch, useAppSelector } from 'shared/redux/redux-utils';

type Props = {
  loading: boolean;
};

const SearchTextInput = ({ loading }: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const query = useAppSelector(state => state.search.query);

  const text = useGetSearchText();

  const [searchText, setsearchText] = useState<string | undefined>(text);

  useEffect(() => {
    if (text && text.length > 0 && searchText !== text) {
      setsearchText(text);
    }
  }, [text]);

  useDebounce(
    () => {
      if (searchText && searchText.length > 0) {
        const newQuery = cloneDeep(query);
        const must = newQuery?.bool?.must ?? [];

        if (Array.isArray(must)) {
          let newMust: QueryDslQueryContainer[] = [];
          if (must.length > 0) {
            newMust = must.map(item => {
              if (item.query_string) {
                item.query_string.query = searchText;
              }
              return item;
            });
          } else {
            newMust.push({
              query_string: {
                query: searchText,
              },
            });
          }

          dispatch(
            setQuery({
              ...newQuery,
              bool: {
                must: newMust,
              },
            }),
          );
        }
      }
    },
    200,
    [searchText],
  );

  const onClick = () => {
    if (!searchText) {
      setsearchText('*');
    } else if (searchText) {
      /* dispatch(
        setQuery({
          ...query,
          query_string: {
            query: searchText,
          },
        }),
      );*/
    }
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setsearchText(event.target.value);
  };

  return (
    <>
      <Box display="flex" alignItems="center" flexWrap="wrap">
        <Box
          display="flex"
          sx={{
            fontSize: 20,
            fontWeight: 'bold',
          }}
        >
          <SearchIcon sx={{ fontSize: 30 }} />
          <Box ml={1}>{t('searchPage.title')}</Box>
        </Box>
        <Box
          ml={4}
          sx={{
            ml: {
              xs: 0,
              sm: 4,
              md: 6,
            },
          }}
        >
          <TextField
            hiddenLabel
            value={searchText ?? ''}
            size="small"
            onChange={onChange}
            style={{
              minWidth: '400px',
            }}
            slotProps={{
              input: {
                endAdornment: (
                  <>
                    {!loading ? (
                      <IconButton type="button" sx={{ px: 0.8 }} onClick={onClick}>
                        <SearchIcon />
                      </IconButton>
                    ) : (
                      <Box pt={0.5}>
                        <Loader size="1rem" delay={0} />
                      </Box>
                    )}
                  </>
                ),
              },
            }}
          />
        </Box>
      </Box>
    </>
  );
};

export default SearchTextInput;

import { Box } from '@mui/material';
import DOMPurify from 'dompurify';
import { convertFromHTML, convertToHTML } from 'draft-convert';
import { EditorState } from 'draft-js';
import { useEffect, useRef, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import SpecialCharacterTool from 'shared/components/textEditor/SpecialCharacterTool';
import './EscapeTextEditor.scss';

type Props = {
  content?: string | null;
  preview?: boolean;
  onChange?: (content: string) => void;
  disabled?: boolean;
  locale?: 'en' | 'fr';
};

const EscapeTextEditor = ({
  content,
  preview,
  onChange,
  disabled = false,
  locale = 'fr',
}: Props) => {
  const editorRef = useRef<Editor>(null);

  const [editorState, setEditorState] = useState(() =>
    EditorState.createWithContent(convertFromHTML(content ?? '')),
  );

  useEffect(() => {
    if (editorRef.current) {
      editorRef.current.focusEditor();
    }
  }, [editorRef]);

  if (preview) {
    return (
      <Box
        className="escape-text-preview"
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(content ?? ''),
        }}
      ></Box>
    );
  }

  const onEditorStateChange = (editorState: EditorState) => {
    const html = convertToHTML(editorState.getCurrentContent());
    if (!preview && onChange) {
      if (html !== content) {
        onChange(DOMPurify.sanitize(html));
      }
    }
  };

  const onFocus = () => {
    console.log('onFocus');
    console.log(editorRef.current);
  };

  const onBlur = () => {
    console.log('onBlur');
    console.log(editorRef.current);
  };

  return (
    <Editor
      ref={editorRef}
      toolbarClassName="escape-text-editor-toolbar"
      wrapperClassName="escape-text-editor-wrapper"
      editorClassName="escape-text-editor"
      defaultEditorState={editorState}
      onEditorStateChange={onEditorStateChange}
      readOnly={disabled}
      toolbarCustomButtons={[<SpecialCharacterTool />]}
      onBlur={onBlur}
      onFocus={onFocus}
      localization={{
        locale,
      }}
      toolbar={{
        options: [
          'inline',
          'blockType',
          'fontSize',
          'fontFamily',
          'list',
          'textAlign',
          'colorPicker',
          'link',
          'remove',
          'history',
        ],
      }}
    />
  );
};

export default EscapeTextEditor;

import Alert from '@mui/material/Alert/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import { FieldErrors } from 'react-hook-form';
import { showErrors } from 'shared/components/form/form-utils';

type Props = {
  errors?: FieldErrors<any>;
};

const FormDebugError = (props: Props) => {
  const { errors } = props;
  if (!showErrors(errors)) {
    return null;
  }

  return (
    <Box p={2}>
      <Alert severity="error">
        <AlertTitle>Error</AlertTitle>
        <pre>{JSON.stringify(errors, null, 2)}</pre>
      </Alert>
    </Box>
  );
};

export default FormDebugError;

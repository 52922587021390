/* eslint no-console: off */

import { isDev } from 'shared/utils/env-utils';

const blacklistTypes = ['api/'];

const loggerMiddleware = () => (next: any) => (action: any) => {
  if (isDev()) {
    const { type, payload, meta } = action;
    if (!blacklistTypes.some(blacklistType => type.includes(blacklistType))) {
      console.groupCollapsed(type);
      console.log('Payload:', payload);
      console.log('Meta:', meta);
      console.groupEnd();
    }
  }

  return next(action);
};

export default loggerMiddleware;

import { useDeleteJobMutation } from 'services/endpoints/jobEndpoints';
import ConfirmDelete from 'shared/components/confirmDelete/ConfirmDelete';
import { Job } from 'shared/model/types';

type Props = {
  jobs: Job[];
};

const DeleteJobsBtn = ({ jobs }: Props) => {
  const [deletejob] = useDeleteJobMutation();

  const onConfirm = async () => {
    deletejob(jobs);
  };

  return <ConfirmDelete onConfirm={onConfirm} noText />;
};

export default DeleteJobsBtn;

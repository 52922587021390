import Box from '@mui/material/Box/Box';
import { useNavigate } from 'react-router-dom';
import { useDirectusAppLogoUrl } from 'shared/hooks/directus-hook';

const version = import.meta.env.PACKAGE_VERSION;
const title = import.meta.env.VITE_APP_TITLE;

const BrandHeader = () => {
  const navigate = useNavigate();
  const logoUrl = useDirectusAppLogoUrl();

  const onClick = () => {
    navigate('/');
  };

  return (
    <>
      {logoUrl && (
        <Box
          component="img"
          src={`${logoUrl}?key=logo`}
          alt={title}
          sx={{
            marginRight: 2,
            cursor: 'pointer',
          }}
        />
      )}
      <Box
        component="a"
        onClick={onClick}
        className="header-title"
        sx={{
          display: { xs: 'none', md: 'flex' },
        }}
      >
        {title}
      </Box>
      <Box
        component="div"
        className="header-subtitle"
        sx={{
          display: { xs: 'none', md: 'flex' },
        }}
      >
        {version}
      </Box>
    </>
  );
};

export default BrandHeader;

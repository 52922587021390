import { createItem, deleteItems, readItem, readItems, updateItem } from '@directus/sdk';

import directus, { MutationQuery, RequestQuery } from 'config/directus';
import api from 'services/api';
import { UserSearch } from 'shared/model/types';

export const userSearchApi = api
  .enhanceEndpoints({
    addTagTypes: ['userSearch', 'userSearchs'],
  })
  .injectEndpoints({
    overrideExisting: true,
    endpoints: builder => ({
      getUserSearchs: builder.query<UserSearch[], RequestQuery<UserSearch>>({
        queryFn: async q => {
          try {
            const data = await directus.request<UserSearch[]>(readItems('UserSearch', q));
            return { data };
          } catch (error: any) {
            return { error };
          }
        },
        providesTags: ['userSearchs'],
      }),
      getUserSearch: builder.query<UserSearch, { id: string; q?: RequestQuery<UserSearch> }>({
        queryFn: async q => {
          try {
            const data = await directus.request<UserSearch>(readItem('UserSearch', q.id, q.q));
            return { data };
          } catch (error: any) {
            return { error };
          }
        },
        providesTags: ['userSearch'],
      }),
      createUserSearch: builder.mutation<
        UserSearch,
        {
          item: Partial<UserSearch>;
          query?: MutationQuery<UserSearch>;
        }
      >({
        queryFn: async q => {
          try {
            const data = await directus.request<UserSearch>(
              createItem('UserSearch', q.item, q.query),
            );
            return { data };
          } catch (error: any) {
            return { error };
          }
        },
        invalidatesTags: ['userSearch', 'userSearchs'],
      }),
      updateUserSearch: builder.mutation<
        UserSearch,
        {
          id: string;
          item: Partial<UserSearch>;
          query?: MutationQuery<UserSearch>;
        }
      >({
        queryFn: async q => {
          try {
            const data = await directus.request<UserSearch>(
              updateItem('UserSearch', q.id, q.item, q.query),
            );
            return { data };
          } catch (error: any) {
            return { error };
          }
        },
        invalidatesTags: ['userSearch', 'userSearchs'],
      }),
      deleteUserSearch: builder.mutation<void, UserSearch[]>({
        queryFn: async userSearch => {
          try {
            const ids = userSearch.map(item => item.id);
            await directus.request(deleteItems('UserSearch', ids));
            return {
              data: undefined,
            };
          } catch (error: any) {
            return { error };
          }
        },
        invalidatesTags: ['userSearch', 'userSearchs'],
      }),
    }),
  });

export const { useGetUserSearchsQuery, useGetUserSearchQuery, useDeleteUserSearchMutation } =
  userSearchApi;

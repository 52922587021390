import Box from '@mui/material/Box/Box';
import TextField from '@mui/material/TextField';

import { RequestResetPwdInfo } from 'modules/user/resetPwd/ResetPwd.model';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRequestResetPasswordMutation } from 'services/endpoints/UsersEndpoints';
import FormButtons from 'shared/components/form/FormButtons';

import FormTemplate from 'shared/components/form/FormTemplate';
import { useDirectusAppLogoUrl } from 'shared/hooks/directus-hook';
import { addCatchErrorAlert, addSuccessAlert } from 'shared/reducers/alertSlice';

import useRequestResetPwdResolver from 'modules/user/resetPwd/useRequestResetPwdResolver';
import { useAppDispatch } from 'shared/redux/redux-utils';

const RequestResetPwdPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const logoUrl = useDirectusAppLogoUrl();

  const { t } = useTranslation();

  const [requestReset, { isLoading }] = useRequestResetPasswordMutation();

  const resolver = useRequestResetPwdResolver();

  const endUrl = '/';

  const isDisabled = isLoading;

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<RequestResetPwdInfo>({
    defaultValues: {
      email: '',
    },
    resolver,
  });

  const onSubmit = async (data: RequestResetPwdInfo) => {
    try {
      await requestReset({
        email: data.email,
        resetUrl: window.location.origin,
      }).unwrap();
      navigate(endUrl);
      dispatch(addSuccessAlert(t('passwordRequestSuccess')));
    } catch (e) {
      dispatch(addCatchErrorAlert(e));
    }
  };

  return (
    <Box mt={4}>
      <FormTemplate
        titlei18n="resetPassword"
        icon={<img alt="logo" src={`${logoUrl}?key=logo-big`} />}
        errors={errors}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box mt={2} px={4}>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <TextField
                  label={t('email')}
                  {...field}
                  disabled={isDisabled}
                  error={!!errors.email}
                  helperText={errors.email?.message}
                  autoFocus
                />
              )}
            />
          </Box>

          <FormButtons cancelUrl={endUrl} disabled={isDisabled} />
        </form>
      </FormTemplate>
    </Box>
  );
};

export default RequestResetPwdPage;

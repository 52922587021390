import AccountBoxIcon from '@mui/icons-material/AccountBox';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Avatar from '@mui/material/Avatar/Avatar';
import IconButton from '@mui/material/IconButton/IconButton';
import Menu from '@mui/material/Menu/Menu';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import HeaderMenuItem from 'shared/components/header/HeaderMenuItem';
import { useCurrentUserName } from 'shared/hooks/auth-hook';
import { logout } from 'shared/reducers/authSlice';
import { useAppDispatch, useAppSelector } from 'shared/redux/redux-utils';

const ProfileHeaderBtn = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useAppSelector(state => state.auth.currentUser);
  const userName = useCurrentUserName();
  const [anchorElUser, setAnchorElUser] = useState<HTMLElement | null>(null);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const onProfil = () => {
    navigate('/profile');
  };

  const onSignOut = async () => {
    setAnchorElUser(null);
    await dispatch(logout());
    navigate('/');
  };

  const userImg = user?.avatar;

  return (
    <>
      <Tooltip title={userName}>
        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0, marginLeft: 1 }}>
          <Avatar alt={userName} src={userImg as string} />
        </IconButton>
      </Tooltip>
      <Menu
        sx={{ mt: '2rem' }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={!!anchorElUser}
        onClose={handleCloseUserMenu}
      >
        <HeaderMenuItem text={t('profile')} icon={<AccountBoxIcon />} onClick={onProfil} />
        <HeaderMenuItem text={t('logout')} icon={<ExitToAppIcon />} onClick={onSignOut} />
      </Menu>
    </>
  );
};

export default ProfileHeaderBtn;

import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Person, Project, Structure, Team } from 'shared/model/types';

type Props = {
  model: unknown;
  type?: string;
  fontSize?: 'small' | 'medium' | 'large' | number;
};

const ModelName = ({ model, type, fontSize }: Props) => {
  const { t } = useTranslation();
  let name = null;
  if (type === 'person') {
    const person = model as Person;
    const civilite = person.civilite ? t(person.civilite) + ' ' : '';
    name = civilite + person.firstName + ' ' + person.lastName;
  } else if (type === 'team') {
    const team = model as Team;
    name = team.name;
  } else if (type === 'structure') {
    const structure = model as Structure;
    name = structure.name;
  } else if (type === 'project') {
    const project = model as Project;
    name = project.name;
  }

  return (
    <Box
      display="inline"
      fontSize={fontSize}
      sx={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: '2',
        WebkitBoxOrient: 'vertical',
      }}
    >
      {name}
    </Box>
  );
};

export default ModelName;

import { createItem, deleteItems, readItem, readItems, updateItem } from '@directus/sdk';

import directus, { MutationQuery, RequestQuery } from 'config/directus';
import api from 'services/api';

import { Team } from 'shared/model/types';

export const teamApi = api
  .enhanceEndpoints({
    addTagTypes: ['team', 'teams'],
  })
  .injectEndpoints({
    overrideExisting: true,
    endpoints: builder => ({
      getTeams: builder.query<Team[], RequestQuery<Team>>({
        queryFn: async q => {
          try {
            const data = await directus.request<Team[]>(readItems('Team', q));
            return { data };
          } catch (error: any) {
            return { error };
          }
        },
        providesTags: ['teams'],
      }),
      getTeam: builder.query<Team, { id: string; q?: RequestQuery<Team> }>({
        queryFn: async q => {
          try {
            const data = await directus.request<Team>(readItem('Team', q.id, q.q));
            return { data };
          } catch (error: any) {
            return { error };
          }
        },
        providesTags: ['team'],
      }),
      createTeam: builder.mutation<
        Team,
        {
          item: Partial<Team>;
          query?: MutationQuery<Team>;
        }
      >({
        queryFn: async q => {
          try {
            const data = await directus.request<Team>(createItem('Team', q.item, q.query));
            return { data };
          } catch (error: any) {
            return { error };
          }
        },
        invalidatesTags: ['team', 'teams'],
      }),
      updateTeam: builder.mutation<
        Team,
        {
          id: string | number;
          item: Partial<Team>;
          query?: MutationQuery<Team>;
        }
      >({
        queryFn: async q => {
          try {
            const data = await directus.request<Team>(updateItem('Team', q.id, q.item, q.query));
            return { data };
          } catch (error: any) {
            return { error };
          }
        },
        invalidatesTags: ['team', 'teams'],
      }),
      deleteTeam: builder.mutation<void, string[]>({
        queryFn: async ids => {
          try {
            await directus.request(deleteItems('Team', ids));
            return {
              data: undefined,
            };
          } catch (error: any) {
            return { error };
          }
        },
        invalidatesTags: ['team', 'teams'],
      }),
    }),
  });

export const {
  useGetTeamsQuery,
  useGetTeamQuery,
  useCreateTeamMutation,
  useUpdateTeamMutation,
  useDeleteTeamMutation,
} = teamApi;

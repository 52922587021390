import { CollectionType, Query, authentication, createDirectus, rest } from '@directus/sdk';
import { CustomDirectusTypes } from 'shared/model/types';

export type RequestQuery<T> = Query<CustomDirectusTypes, T>;
export type MutationQuery<T> = Query<CustomDirectusTypes, T[]>;

export type TQuery<T extends keyof CustomDirectusTypes> = Query<
  CustomDirectusTypes,
  CollectionType<CustomDirectusTypes, T>
>;

const url = import.meta.env.VITE_DIRECTUS_URL;

const directus = createDirectus<CustomDirectusTypes>(url)
  .with(
    authentication('cookie', {
      credentials: 'include',
      autoRefresh: true,
      msRefreshBeforeExpires: 20000,
    }),
  )
  .with(
    rest({
      credentials: 'include',
    }),
  );

export default directus;

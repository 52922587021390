import { Action, configureStore, ThunkAction, ThunkDispatch } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { api } from 'services/api';
import authReducer from 'shared/reducers/authSlice';
import { rtkQueryErrorForbidden, rtkQueryErrorLogger } from 'shared/redux/rtkQueryErrorMiddleware';

import searchApi from 'services/searchApi';
import alertReducer from 'shared/reducers/alertSlice';
import drawerReducer from 'shared/reducers/drawerSlice';
import searchReducer from 'shared/reducers/searchSlice';
import loggerMiddleware from 'shared/redux/logger-middleware';

export const store = configureStore({
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(
      api.middleware,
      searchApi.middleware,
      rtkQueryErrorLogger,
      rtkQueryErrorForbidden,
      loggerMiddleware,
    ),
  reducer: {
    auth: authReducer,
    snacks: alertReducer,
    search: searchReducer,
    drawer: drawerReducer,
    [api.reducerPath]: api.reducer,
    [searchApi.reducerPath]: searchApi.reducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
export type ThunkAppDispatch = ThunkDispatch<RootState, void, Action>;

setupListeners(store.dispatch);

import { Box, Grid } from '@mui/material';
import PersonScientificAttachmentsList from 'modules/sheet/person/PersonScientificAttachmentsList';
import { useGetPerson } from 'modules/sheet/sheetHook';
import { useTranslation } from 'react-i18next';
import { useUpdatePersonMutation } from 'services/endpoints/PersonEndpoints';
import EditableCartTextEditor from 'shared/components/Card/EditableCartTextEditor';

const PersonMainTab = () => {
  const { t } = useTranslation();
  const { data } = useGetPerson();

  const [updatePerson, { isLoading }] = useUpdatePersonMutation();

  if (!data) {
    return null;
  }

  const onDescriptionChange = async (content: string) => {
    return updatePerson({
      id: data.id,
      item: {
        description: content,
      },
    });
  };

  const onPrivateDescChange = async (content: string) => {
    return updatePerson({
      id: data.id,
      item: {
        privateDescription: content,
      },
    });
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <Box mb={2}>
            <EditableCartTextEditor
              title={t('description')}
              content={data.description}
              onValid={onDescriptionChange}
              disabled={isLoading}
            />
          </Box>
          <EditableCartTextEditor
            title={t('privateDescription')}
            content={data.privateDescription}
            onValid={onPrivateDescChange}
            disabled={isLoading}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <PersonScientificAttachmentsList person={data} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default PersonMainTab;

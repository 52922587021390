import { Box, TextField } from '@mui/material';
import { usePersonCreationResolver } from 'modules/sheet/person/usePersonResolver';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FormButtons from 'shared/components/form/FormButtons';
import FormTemplate from 'shared/components/form/FormTemplate';
import CiviliteSelect from 'shared/components/select/CiviliteSelect';
import { CiviliteType, PersonFormType, PersonIcon } from 'shared/model/def/person.def';
import { Person } from 'shared/model/types';
import { closeAllDrawers } from 'shared/reducers/drawerSlice';
import { useAppDispatch } from 'shared/redux/redux-utils';

type Props = {
  item: Partial<Person>;
  disabled?: boolean;
  onSubmit: (team: PersonFormType) => void;
};

const PersonCreationForm = ({ item, disabled = false, onSubmit }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const resolver = usePersonCreationResolver();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<PersonFormType>({
    defaultValues: {
      ...item,
    },
    resolver,
  });

  const onCancel = () => {
    dispatch(closeAllDrawers());
  };

  return (
    <Box>
      <FormTemplate fullWidth titlei18n="person.add" icon={<PersonIcon />} errors={errors}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box mt={2}>
            <Controller
              name="civilite"
              control={control}
              rules={{ required: t('form.errors.required') }}
              render={({ field: { onChange, value } }) => (
                <CiviliteSelect
                  value={value as CiviliteType}
                  onChange={onChange}
                  error={!!errors.civilite}
                  helperText={errors.civilite?.message}
                />
              )}
            />
          </Box>
          <Box mt={2}>
            <Controller
              name="firstName"
              control={control}
              rules={{ required: t('form.errors.required') }}
              render={({ field }) => (
                <TextField
                  label={t('firstName')}
                  {...field}
                  error={!!errors.firstName}
                  helperText={errors.firstName?.message}
                />
              )}
            />
          </Box>
          <Box mt={2}>
            <Controller
              name="lastName"
              control={control}
              rules={{ required: t('form.errors.required') }}
              render={({ field }) => (
                <TextField
                  label={t('lastName')}
                  {...field}
                  error={!!errors.lastName}
                  helperText={errors.lastName?.message}
                />
              )}
            />
          </Box>
          <FormButtons onCancel={onCancel} disabled={disabled} />
        </form>
      </FormTemplate>
    </Box>
  );
};

export default PersonCreationForm;

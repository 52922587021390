import { AggregationsStringTermsBucket } from '@elastic/elasticsearch/lib/api/types';
import { Box, List } from '@mui/material';
import SearchIndexFacetItem from 'modules/search/SearchIndexFacetItem';

type Props = {
  label: string;
  disabled: boolean;
  agg?: any;
  parentKey?: string;
};

const SearchFacets = ({ label, agg, parentKey, disabled }: Props) => {
  if (!agg) {
    return null;
  }

  const buckets = agg.buckets as AggregationsStringTermsBucket[];

  if (buckets.length === 0) {
    return null;
  }

  return (
    <List
      dense
      sx={{
        pb: 0,
      }}
      subheader={
        <Box
          borderBottom={1}
          borderColor="primary.main"
          color="primary.main"
          fontWeight="bold"
          py={1}
        >
          {label}
        </Box>
      }
    >
      {buckets.map(bucket => (
        <SearchIndexFacetItem
          key={bucket.key}
          disabled={disabled}
          bucket={bucket}
          parentKey={parentKey}
          currentAgg={agg.key}
        />
      ))}
    </List>
  );
};

export default SearchFacets;

import { createItem, deleteItems, readItem, readItems, updateItem } from '@directus/sdk';

import directus, { MutationQuery, RequestQuery } from 'config/directus';
import { structureApi } from 'services/endpoints/StructureEndpoints';
import { StructureTutelle } from 'shared/model/types';

export const extendedApi = structureApi
  .enhanceEndpoints({
    addTagTypes: ['StructureTutelle', 'StructureTutelles'],
  })
  .injectEndpoints({
    overrideExisting: true,
    endpoints: builder => ({
      getStructureTutelles: builder.query<StructureTutelle[], RequestQuery<StructureTutelle>>({
        queryFn: async q => {
          try {
            const data = await directus.request<StructureTutelle[]>(
              readItems('StructureTutelle', q),
            );
            return { data };
          } catch (error: any) {
            return { error };
          }
        },
        providesTags: ['StructureTutelles'],
      }),
      getStructureTutelle: builder.query<
        StructureTutelle,
        { id: string; q?: RequestQuery<StructureTutelle> }
      >({
        queryFn: async q => {
          try {
            const data = await directus.request<StructureTutelle>(
              readItem('StructureTutelle', q.id, q.q),
            );
            return { data };
          } catch (error: any) {
            return { error };
          }
        },
        providesTags: ['StructureTutelle'],
      }),
      createStructureTutelle: builder.mutation<
        StructureTutelle,
        {
          item: Partial<StructureTutelle>;
          query?: MutationQuery<StructureTutelle>;
        }
      >({
        queryFn: async q => {
          try {
            const data = await directus.request<StructureTutelle>(
              createItem('StructureTutelle', q.item, q.query),
            );
            return { data };
          } catch (error: any) {
            return { error };
          }
        },
        invalidatesTags: ['StructureTutelle', 'StructureTutelles', 'structure'],
      }),
      updateStructureTutelle: builder.mutation<
        StructureTutelle,
        {
          id: string | number;
          item: Partial<StructureTutelle>;
          query?: MutationQuery<StructureTutelle>;
        }
      >({
        queryFn: async q => {
          try {
            const data = await directus.request<StructureTutelle>(
              updateItem('StructureTutelle', q.id, q.item, q.query),
            );
            return { data };
          } catch (error: any) {
            return { error };
          }
        },
        invalidatesTags: ['StructureTutelle', 'StructureTutelles', 'structure'],
      }),
      deleteStructureTutelle: builder.mutation<void, StructureTutelle[]>({
        queryFn: async StructureTutelle => {
          try {
            const ids = StructureTutelle.map(item => item.id);
            await directus.request(deleteItems('StructureTutelle', ids));
            return {
              data: undefined,
            };
          } catch (error: any) {
            return { error };
          }
        },
        invalidatesTags: ['StructureTutelle', 'StructureTutelles', 'structure'],
      }),
    }),
  });

export const {
  useGetStructureTutellesQuery,
  useGetStructureTutelleQuery,
  useCreateStructureTutelleMutation,
  useUpdateStructureTutelleMutation,
  useDeleteStructureTutelleMutation,
} = extendedApi;

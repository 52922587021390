import { Button } from '@mui/material';
import { EditorState, Modifier } from 'draft-js';
import { useState } from 'react';

import SpecialCharacterDialog from 'shared/components/textEditor/SpecialCharacterDialog';

type Props = {
  onChange?: (newEditorState: EditorState) => void;
  editorState?: EditorState;
};

const SpecialCharacterTool = ({ onChange, editorState }: Props) => {
  const [open, setOpen] = useState(false);

  const onClick = () => {
    setOpen(true);
  };

  const close = () => {
    setOpen(false);
  };

  const onConfirm = (str: string) => {
    setOpen(false);

    const codeStr = str.replace('&#', '').replace(';', '');
    const toAdd = String.fromCodePoint(Number(codeStr));

    if (editorState) {
      const contentState = editorState.getCurrentContent();
      const selection = editorState.getSelection();

      const newContent = Modifier.insertText(
        contentState,
        selection,
        toAdd,
        editorState.getCurrentInlineStyle(),
      );
      const nextState = EditorState.push(editorState, newContent, 'insert-characters');

      if (onChange) {
        onChange(nextState);
      }
    }
  };

  return (
    <>
      <Button variant="outlined" color="inherit" size="small" onClick={onClick}>
        &Omega;
      </Button>
      <SpecialCharacterDialog open={open} onCancel={close} onConfirm={onConfirm} />
    </>
  );
};

export default SpecialCharacterTool;

export enum Role {
  Admin = 'ROLE_ADMIN',
  User = 'ROLE_USER',
}

export interface IdbRole {
  id: number;
  name: Role;
}

export const roles: Role[] = [Role.Admin, Role.User];

export const dbRoles: IdbRole[] = [
  {
    id: 1,
    name: Role.Admin,
  },
  {
    id: 2,
    name: Role.User,
  },
];

import { createItem, deleteItems, readItem, readItems, updateItem } from '@directus/sdk';

import directus, { MutationQuery, RequestQuery } from 'config/directus';
import api from 'services/api';
import { Structure } from 'shared/model/types';

export const structureApi = api
  .enhanceEndpoints({
    addTagTypes: ['structure', 'structures'],
  })
  .injectEndpoints({
    overrideExisting: true,
    endpoints: builder => ({
      getStructures: builder.query<Structure[], RequestQuery<Structure>>({
        queryFn: async q => {
          try {
            const data = await directus.request<Structure[]>(readItems('Structure', q));
            return { data };
          } catch (error: any) {
            return { error };
          }
        },
        providesTags: ['structures'],
      }),
      getStructure: builder.query<Structure, { id: string; q?: RequestQuery<Structure> }>({
        queryFn: async q => {
          try {
            const data = await directus.request<Structure>(readItem('Structure', q.id, q.q));
            return { data };
          } catch (error: any) {
            return { error };
          }
        },
        providesTags: ['structure'],
      }),
      createStructure: builder.mutation<
        Structure,
        {
          item: Partial<Structure>;
          query?: MutationQuery<Structure>;
        }
      >({
        queryFn: async q => {
          try {
            const data = await directus.request<Structure>(
              createItem('Structure', q.item, q.query),
            );
            return { data };
          } catch (error: any) {
            return { error };
          }
        },
        invalidatesTags: ['structure', 'structures'],
      }),
      updateStructure: builder.mutation<
        Structure,
        {
          id: string | number;
          item: Partial<Structure>;
          query?: MutationQuery<Structure>;
        }
      >({
        queryFn: async q => {
          try {
            const data = await directus.request<Structure>(
              updateItem('Structure', q.id, q.item, q.query),
            );
            return { data };
          } catch (error: any) {
            return { error };
          }
        },
        invalidatesTags: ['structure', 'structures'],
      }),
      deleteStructure: builder.mutation<void, string[]>({
        queryFn: async ids => {
          try {
            await directus.request(deleteItems('Structure', ids));
            return {
              data: undefined,
            };
          } catch (error: any) {
            return { error };
          }
        },
        invalidatesTags: ['structure', 'structures'],
      }),
    }),
  });

export const {
  useGetStructuresQuery,
  useGetStructureQuery,
  useCreateStructureMutation,
  useUpdateStructureMutation,
  useDeleteStructureMutation,
} = structureApi;

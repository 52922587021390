import { createItem, deleteItems, readItem, readItems, updateItem } from '@directus/sdk';

import directus, { MutationQuery, RequestQuery } from 'config/directus';
import { teamApi } from 'services/endpoints/TeamEndpoints';

import { Tutelle } from 'shared/model/types';

export const extendedApi = teamApi
  .enhanceEndpoints({
    addTagTypes: ['tutelle', 'tutelles'],
  })
  .injectEndpoints({
    overrideExisting: true,
    endpoints: builder => ({
      getTutelles: builder.query<Tutelle[], RequestQuery<Tutelle>>({
        queryFn: async q => {
          const data = await directus.request<Tutelle[]>(readItems('Tutelle', q));
          return { data };
        },
        providesTags: ['tutelles'],
      }),
      getTutelle: builder.query<Tutelle, { id: string; q?: RequestQuery<Tutelle> }>({
        queryFn: async q => {
          const data = await directus.request<Tutelle>(readItem('Tutelle', q.id, q.q));
          return { data };
        },
        providesTags: ['tutelle'],
      }),
      createTutelle: builder.mutation<
        Tutelle,
        {
          item: Partial<Tutelle>;
          query?: MutationQuery<Tutelle>;
        }
      >({
        queryFn: async q => {
          const data = await directus.request<Tutelle>(createItem('Tutelle', q.item, q.query));
          return { data };
        },
        invalidatesTags: ['tutelle', 'tutelles', 'team'],
      }),
      updateTutelle: builder.mutation<
        Tutelle,
        {
          id: string | number;
          item: Partial<Tutelle>;
          query?: MutationQuery<Tutelle>;
        }
      >({
        queryFn: async q => {
          const data = await directus.request<Tutelle>(
            updateItem('Tutelle', q.id, q.item, q.query),
          );
          return { data };
        },
        invalidatesTags: ['tutelle', 'tutelles', 'team'],
      }),
      deleteTutelle: builder.mutation<void, Tutelle[]>({
        queryFn: async tutelle => {
          const ids = tutelle.map(item => item.id);
          await directus.request(deleteItems('Tutelle', ids));

          return {
            data: undefined,
          };
        },
        invalidatesTags: ['tutelle', 'tutelles', 'team'],
      }),
    }),
  });

export const {
  useGetTutellesQuery,
  useGetTutelleQuery,
  useCreateTutelleMutation,
  useUpdateTutelleMutation,
  useDeleteTutelleMutation,
} = extendedApi;

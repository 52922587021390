import { Box, TextField } from '@mui/material';
import useStructureResolver from 'modules/sheet/structure/useStructureResolver';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import GeositeAutocomplete from 'shared/components/autocomplete/GeositeAutocomplete';
import PersonAutocomplete from 'shared/components/autocomplete/PersonAutocomplete';
import ResponsibleFunctionAutocomplete from 'shared/components/autocomplete/ResponsibleFunctionAutocomplete';
import StructureTypeAutocomplete from 'shared/components/autocomplete/StructureTypeAutocomplete';
import FormButtons from 'shared/components/form/FormButtons';
import FormTemplate from 'shared/components/form/FormTemplate';
import { StructureFormType } from 'shared/model/def/structure.def';
import { TeamIcon } from 'shared/model/def/team.def';
import { Geosite, Person, Structure, StructureType } from 'shared/model/types';
import { closeAllDrawers } from 'shared/reducers/drawerSlice';
import { useAppDispatch } from 'shared/redux/redux-utils';

type Props = {
  structure: Partial<Structure>;
  disabled?: boolean;
  onSubmit: (structure: StructureFormType) => void;
};

const StructureForm = ({ structure, disabled = false, onSubmit }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const resolver = useStructureResolver();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<StructureFormType>({
    defaultValues: {
      ...structure,
    },
    resolver,
  });

  const onCancel = () => {
    dispatch(closeAllDrawers());
  };

  const titleI18n = structure.id ? 'structure.edit' : 'structure.add';

  return (
    <Box>
      <FormTemplate fullWidth titlei18n={titleI18n} icon={<TeamIcon />} errors={errors}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box mt={2}>
            <Controller
              name="name"
              control={control}
              rules={{ required: t('form.errors.required') }}
              render={({ field }) => (
                <TextField
                  label={t('name')}
                  {...field}
                  error={!!errors.name}
                  helperText={errors.name?.message}
                />
              )}
            />
          </Box>
          <Box mt={2}>
            <Controller
              name="responsible"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <PersonAutocomplete
                    label={t('team.person')}
                    value={value as Person | null}
                    onChange={onChange}
                    error={!!errors.responsible}
                    helperText={errors.responsible?.message}
                  />
                );
              }}
            />
          </Box>
          <Box mt={2}>
            <Controller
              name="responsibleFunction"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <ResponsibleFunctionAutocomplete
                    value={value as Person | null}
                    onChange={onChange}
                    error={!!errors.responsibleFunction}
                    helperText={errors.responsibleFunction?.message}
                  />
                );
              }}
            />
          </Box>
          <Box mt={2}>
            <Controller
              name="shortName"
              control={control}
              render={({ field }) => (
                <TextField
                  label={t('structure.shortName')}
                  {...field}
                  error={!!errors.shortName}
                  helperText={errors.shortName?.message}
                />
              )}
            />
          </Box>
          <Box mt={2}>
            <Controller
              name="type"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <StructureTypeAutocomplete
                    label={t('structure.type')}
                    value={value as StructureType | null}
                    onChange={onChange}
                    error={!!errors.type}
                    helperText={errors.type?.message}
                  />
                );
              }}
            />
          </Box>
          <Box mt={2}>
            <Controller
              name="geosite"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <GeositeAutocomplete
                    label={t('geosite.geosite')}
                    value={value as Geosite | null}
                    onChange={onChange}
                    error={!!errors.geosite}
                    helperText={errors.geosite?.message}
                  />
                );
              }}
            />
          </Box>
          <Box mt={2}>
            <Controller
              name="address"
              control={control}
              render={({ field }) => (
                <TextField
                  label={t('adresse')}
                  {...field}
                  error={!!errors.address}
                  helperText={errors.address?.message}
                />
              )}
            />
          </Box>
          <Box mt={2}>
            <Controller
              name="address_2"
              control={control}
              render={({ field }) => (
                <TextField
                  label={t('adresse2')}
                  {...field}
                  error={!!errors.address_2}
                  helperText={errors.address_2?.message}
                />
              )}
            />
          </Box>
          <Box mt={2}>
            <Controller
              name="cp"
              control={control}
              render={({ field }) => (
                <TextField
                  label={t('cp')}
                  {...field}
                  error={!!errors.cp}
                  helperText={errors.cp?.message}
                />
              )}
            />
          </Box>
          <Box mt={2}>
            <Controller
              name="city"
              control={control}
              render={({ field }) => (
                <TextField
                  label={t('city')}
                  {...field}
                  error={!!errors.city}
                  helperText={errors.city?.message}
                />
              )}
            />
          </Box>
          <Box mt={2}>
            <Controller
              name="phone"
              control={control}
              render={({ field }) => (
                <TextField
                  label={t('phone')}
                  {...field}
                  error={!!errors.phone}
                  helperText={errors.phone?.message}
                />
              )}
            />
          </Box>
          <Box mt={2}>
            <Controller
              name="fax"
              control={control}
              render={({ field }) => (
                <TextField
                  label={t('fax')}
                  {...field}
                  error={!!errors.fax}
                  helperText={errors.fax?.message}
                />
              )}
            />
          </Box>
          <Box mt={2}>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <TextField
                  label={t('email')}
                  {...field}
                  error={!!errors.email}
                  helperText={errors.email?.message}
                />
              )}
            />
          </Box>
          <Box mt={2}>
            <Controller
              name="webSite"
              control={control}
              render={({ field }) => (
                <TextField
                  label={t('webSite')}
                  {...field}
                  error={!!errors.webSite}
                  helperText={errors.webSite?.message}
                />
              )}
            />
          </Box>
          <FormButtons onCancel={onCancel} disabled={disabled} />
        </form>
      </FormTemplate>
    </Box>
  );
};

export default StructureForm;

import InviteUserPage from 'modules/admin/users/InviteUserPage';
import UsersPage from 'modules/admin/users/UsersPage';
import { Outlet, RouteObject } from 'react-router-dom';
import PrivateRoute from 'shared/routes/privateRoute';
import { Role } from 'shared/types/role';

const routes: RouteObject[] = [
  {
    path: '/admin',
    element: (
      <PrivateRoute roles={[Role.Admin]}>
        <Outlet />
      </PrivateRoute>
    ),
    children: [
      {
        path: 'users',
        element: <UsersPage />,
      },
      {
        path: 'users/invite',
        element: <InviteUserPage />,
      },
    ],
  },
];

export default routes;

import { useDeleteUsersMutation } from 'services/endpoints/UsersEndpoints';
import ConfirmDelete from 'shared/components/confirmDelete/ConfirmDelete';
import { DirectusUsers } from 'shared/model/types';

type Props = {
  users: DirectusUsers[];
  size?: 'small' | 'medium' | 'large';
};

const DeleteUserBtn = ({ users, size }: Props) => {
  const [deleteUsers] = useDeleteUsersMutation();

  const onDelete = () => {
    const ids = users.map(u => u.id);
    deleteUsers(ids);
  };

  return <ConfirmDelete size={size} onConfirm={onDelete} />;
};

export default DeleteUserBtn;

import StructureCreationForm from 'modules/sheet/structure/StructureCreationForm';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useCreateStructureMutation } from 'services/endpoints/StructureEndpoints';
import OpenDrawerButton from 'shared/components/buttons/OpenDrawerButton';
import ModelIcon from 'shared/model/def/ModelIcon';
import { StructureFormType } from 'shared/model/def/structure.def';
import { closeAllDrawers } from 'shared/reducers/drawerSlice';
import { useAppDispatch } from 'shared/redux/redux-utils';

const CreateStructureBtn = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [create, { isLoading }] = useCreateStructureMutation();

  const onSubmit = async (newItem: StructureFormType) => {
    const saved = await create({
      item: newItem,
    }).unwrap();

    navigate(`/sheet/structure/${saved.id}/main`);

    dispatch(closeAllDrawers());
  };
  return (
    <OpenDrawerButton
      type="menuItem"
      text={t('structure.structure')}
      icon={<ModelIcon model="structure" />}
    >
      <StructureCreationForm item={{}} onSubmit={onSubmit} disabled={isLoading} />
    </OpenDrawerButton>
  );
};

export default CreateStructureBtn;

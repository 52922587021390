import { zodResolver } from '@hookform/resolvers/zod';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

// Begin with https://transform.tools/typescript-to-zod

const useConfirmInviteResolver = () => {
  const { t } = useTranslation();

  const requiredMessage = t('form.errors.required');

  const schema = z.object({
    email: z.string().min(1, { message: requiredMessage }),
    password: z.string().min(1, { message: requiredMessage }),
  });

  return zodResolver(schema);
};
export default useConfirmInviteResolver;

import { useAppSelector } from 'shared/redux/redux-utils';

import isEmpty from 'lodash/isEmpty';
import { useDirectusDefaultLanguage } from 'shared/hooks/directus-hook';
import { Role } from 'shared/types/role';

export const useIsAuthenticated = () => {
  const currentUser = useAppSelector(state => state.auth.currentUser);
  const role = useAppSelector(state => state.auth.role);
  return !isEmpty(currentUser) && !isEmpty(role);
};

export const useIsAdmin = () => {
  const role = useAppSelector(state => state.auth.role);
  return role?.name === 'Administrator';
};

export const useIsUser = () => {
  const isAuthenticated = useIsAuthenticated();
  const isAdmin = useIsAdmin();
  return isAuthenticated && !isAdmin;
};

export const useCurrentUserRole = () => {
  const isAdmin = useIsAdmin();
  return isAdmin ? Role.Admin : Role.User;
};

export const useCurrentUserEmail = () => {
  const user = useAppSelector(state => state.auth.currentUser);
  return user?.email;
};

export const useCurrentUserName = () => {
  const user = useAppSelector(state => state.auth.currentUser);
  return user?.first_name + ' ' + user?.last_name;
};

export const useCurrentLanguage = () => {
  const user = useAppSelector(state => state.auth.currentUser);
  const defaultLanguage = useDirectusDefaultLanguage();
  return user?.language ?? defaultLanguage;
};

export const useCurrentUserId = () => {
  const user = useAppSelector(state => state.auth.currentUser);
  return user?.id;
};

import { Box } from '@mui/material';
import SearchResultPerson from 'modules/search/results/SearchResultPerson';
import SearchResultProject from 'modules/search/results/SearchResultProject';
import SearchResultStructure from 'modules/search/results/SearchResultStructure';
import SearchResultTeam from 'modules/search/results/SearchResultTeam';
import { Person, Project, Structure, Team } from 'shared/model/types';

type Props = {
  model: unknown;
  type?: string;
};

const SearchResultDetails = ({ model, type }: Props) => {
  if (type === 'person') {
    return <SearchResultPerson person={model as Person} />;
  } else if (type === 'team') {
    return <SearchResultTeam team={model as Team} />;
  } else if (type === 'structure') {
    return <SearchResultStructure structure={model as Structure} />;
  } else if (type === 'project') {
    return <SearchResultProject project={model as Project} />;
  }
  return <Box>{JSON.stringify(model)}</Box>;
};

export default SearchResultDetails;

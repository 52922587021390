import { Box, Card, CardContent, CardHeader, Paper } from '@mui/material';
import { ReactNode } from 'react';

type Props = {
  title: ReactNode;
  actions?: ReactNode;
  children: ReactNode;
};

const EditableCard = ({ children, title, actions }: Props) => {
  return (
    <Paper elevation={3}>
      <Card>
        <CardHeader
          sx={{
            p: 1.2,
            '& .MuiCardHeader-title ': {
              fontSize: '1.1rem',
            },
          }}
          title={title}
          action={<Box mr={1}>{actions}</Box>}
        />
        <CardContent sx={{ p: 1, pt: 0 }}>{children}</CardContent>
      </Card>
    </Paper>
  );
};

export default EditableCard;

import AddIcon from '@mui/icons-material/Add';
import JobForm from 'modules/sheet/structure/jobs/JobForm';
import { useTranslation } from 'react-i18next';
import { useCreateJobMutation } from 'services/endpoints/jobEndpoints';
import OpenDrawerButton from 'shared/components/buttons/OpenDrawerButton';
import { JobFormType } from 'shared/model/def/job.def';
import { Structure } from 'shared/model/types';
import { closeAllDrawers } from 'shared/reducers/drawerSlice';
import { useAppDispatch } from 'shared/redux/redux-utils';

type Props = {
  structure: Structure;
};

const CreateStructureJobBtn = ({ structure }: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [create, { isLoading }] = useCreateJobMutation();

  const onSubmit = async (newItem: JobFormType) => {
    newItem.structure = structure.id;
    await create({
      item: newItem,
    }).unwrap();

    dispatch(closeAllDrawers());
  };

  return (
    <OpenDrawerButton type="button" icon={<AddIcon />}>
      <JobForm
        job={{
          label: '',
          sort: 0,
          structure,
        }}
        disabledStructureSelection
        onSubmit={onSubmit}
        disabled={isLoading}
      />
    </OpenDrawerButton>
  );
};

export default CreateStructureJobBtn;

import TeamForm from 'modules/sheet/team/TeamForm';
import { useUpdateTeamMutation } from 'services/endpoints/TeamEndpoints';
import OpenDrawerButton from 'shared/components/buttons/OpenDrawerButton';
import { TeamFormType } from 'shared/model/def/team.def';
import { Team } from 'shared/model/types';
import { closeAllDrawers } from 'shared/reducers/drawerSlice';
import { useAppDispatch } from 'shared/redux/redux-utils';

type Props = {
  team: Team;
};

const EditTeamBtn = ({ team }: Props) => {
  const dispatch = useAppDispatch();

  const [updateTeam, { isLoading }] = useUpdateTeamMutation();

  const onSubmit = async (newTeam: TeamFormType) => {
    await updateTeam({
      id: team.id,
      item: newTeam,
    }).unwrap();

    dispatch(closeAllDrawers());
  };
  return (
    <OpenDrawerButton>
      <TeamForm team={team} onSubmit={onSubmit} disabled={isLoading} />
    </OpenDrawerButton>
  );
};

export default EditTeamBtn;

import { List, ListItem, ListItemText } from '@mui/material';
import { MRT_RowSelectionState } from 'material-react-table';
import { useTranslation } from 'react-i18next';
import { useUpdateStructureMutation } from 'services/endpoints/StructureEndpoints';
import OpenDrawerButton from 'shared/components/buttons/OpenDrawerButton';
import EditableCard from 'shared/components/Card/EditableCard';
import IconValue from 'shared/components/IconValue';
import ScientificAttachmentsSelection from 'shared/components/scientificAttachments/ScientificAttachmentsSelection';
import { ScientificAttachmentIcon } from 'shared/model/def/ScientificAttachment.def';
import { ScientificAttachment, Structure, StructureScientificAttachment } from 'shared/model/types';
import { closeAllDrawers } from 'shared/reducers/drawerSlice';
import { useAppDispatch } from 'shared/redux/redux-utils';

type Props = {
  structure: Structure;
};

const StructureScientificAttachmentsList = ({ structure }: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [updateStructure] = useUpdateStructureMutation();

  const ssas = structure.scientificAttachments
    ? (structure.scientificAttachments as StructureScientificAttachment[])
    : [];

  const scientificAttachments = ssas.map(
    tsa => tsa.scientificAttachment_id as ScientificAttachment,
  );

  const onChange = async (rowSelection: MRT_RowSelectionState) => {
    const ids = Object.keys(rowSelection).map(id => parseInt(id));
    await updateStructure({
      id: structure.id,
      item: {
        scientificAttachments: ids.map(id => ({ scientificAttachment_id: id })),
      },
    }).unwrap();

    dispatch(closeAllDrawers());
  };

  return (
    <EditableCard
      title={
        <IconValue icon={<ScientificAttachmentIcon />}>
          {t('scientificAttachments.title')}
        </IconValue>
      }
      actions={
        <OpenDrawerButton>
          <ScientificAttachmentsSelection
            selected={scientificAttachments.map(item => item.id)}
            onChange={onChange}
            tableSx={{
              height: 'calc(100vh - 130px)',
            }}
          />
        </OpenDrawerButton>
      }
    >
      <List dense>
        {scientificAttachments.map(item => (
          <ListItem key={item.id}>
            <ListItemText primary={item.name} />
          </ListItem>
        ))}
      </List>
    </EditableCard>
  );
};

export default StructureScientificAttachmentsList;

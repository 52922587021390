import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import BrandHeader from 'layout/BrandHeader';

import LoginHeaderBtn from 'layout/LoginHeaderBtn';
import SearchHeaderMenu from 'layout/SearchHeaderMenu';
import AddHeaderMenu from 'layout/header/AddHeaderMenu';
import AdminHeaderBtn from 'layout/header/AdminHeaderBtn';
import HistoryHeaderMenu from 'layout/header/HistoryHeaderMenu';
import ProfileHeaderBtn from 'layout/header/ProfileHeaderBtn';
import { useIsAdmin, useIsAuthenticated } from 'shared/hooks/auth-hook';
import './header.scss';

const Header = () => {
  const isAdmin = useIsAdmin();
  const isAuthenticated = useIsAuthenticated();

  return (
    <AppBar sx={{ bgcolor: '#353d47' }}>
      <Toolbar variant="dense">
        <BrandHeader />
        {isAuthenticated ? (
          <>
            <SearchHeaderMenu />
            <HistoryHeaderMenu />
            <AddHeaderMenu />
            {isAdmin && (
              <>
                <AdminHeaderBtn />
              </>
            )}
            <Box sx={{ flexGrow: 0, display: 'flex' }}>
              <ProfileHeaderBtn />
            </Box>
          </>
        ) : (
          <Box>
            <LoginHeaderBtn />
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;

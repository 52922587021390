import AccountBoxIcon from '@mui/icons-material/AccountBox';
import Box from '@mui/material/Box/Box';
import Chip from '@mui/material/Chip/Chip';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper/Paper';
import { useTranslation } from 'react-i18next';
import PageTitle from 'shared/components/PageTitle';
import { useCurrentLanguage, useCurrentUserEmail, useCurrentUserName, useIsAdmin } from 'shared/hooks/auth-hook';

const ProfilePage = () => {
  const { t } = useTranslation();

  const name = useCurrentUserName();
  const email = useCurrentUserEmail();
  const lang = useCurrentLanguage();
  const isAdmin = useIsAdmin();

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} md={8} lg={6} xl={4}>
        <Paper sx={{ padding: 2, marginTop: 2 }}>
          <Box padding={2}>
            <PageTitle i18n="profile" icon={<AccountBoxIcon fontSize="large" />} />

            <Box pb={2} className="label-value">
              <Box>{t('name')}</Box>
              <Box>{name}</Box>
            </Box>
            <Box pb={2} className="label-value">
              <Box>{t('email')}</Box>
              <Box>{email}</Box>
            </Box>
            <Box pb={2} className="label-value">
              <Box>{t('role')}</Box>
              <Box display="flex">{isAdmin && <Chip variant="outlined" label="Admin" />}</Box>
            </Box>
            <Box pb={2} className="label-value">
              <Box>{t('lang')}</Box>
              <Box>{lang}</Box>
            </Box>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default ProfilePage;

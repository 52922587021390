import { AggregationsAggregationContainer } from '@elastic/elasticsearch/lib/api/types';

export const personFacets: Record<string, AggregationsAggregationContainer> = {
  _index: {
    terms: {
      field: '_index',
      size: 30,
    },
    aggs: {
      inOffice: {
        terms: {
          field: 'inOffice',
          value_type: 'boolean',
          size: 2,
        },
      },
      'jobs.metier.name': {
        terms: {
          field: 'jobs.metier.name.keyword',
          size: 30,
        },
      },
      'jobs.statut.name': {
        terms: {
          field: 'jobs.statut.name.keyword',
          size: 30,
        },
      },
      'jobs.structure.geosite.name': {
        terms: {
          field: 'jobs.structure.geosite.name.keyword',
          size: 30,
        },
      },
      'scientificAttachment.scientificAttachment_id.name': {
        terms: {
          field: 'scientificAttachment.scientificAttachment_id.name.keyword',
          size: 30,
        },
      },
      subType: {
        terms: {
          field: 'subType.keyword',
          size: 30,
        },
      },
    },
  },
};

import { useDeleteTeamMemberMutation } from 'services/endpoints/TeamMemberEndpoints';
import ConfirmDelete from 'shared/components/confirmDelete/ConfirmDelete';
import { TeamMember } from 'shared/model/types';

type Props = {
  teamMembers: TeamMember[];
  size?: 'small' | 'medium' | 'large';
};

const DeleteTeamMembersBtn = ({ teamMembers, size }: Props) => {
  const [deleteTeamMembers] = useDeleteTeamMemberMutation();

  const onDelete = () => {
    deleteTeamMembers(teamMembers);
  };

  return <ConfirmDelete size={size} onConfirm={onDelete} noText />;
};

export default DeleteTeamMembersBtn;

import {
  MRT_ColumnDef,
  MRT_ColumnFiltersState,
  MRT_SortingState,
  type MRT_RowData,
  type MRT_TableOptions,
} from 'material-react-table';
import { useCurrentLanguage } from 'shared/hooks/auth-hook';

import { IfAny, QueryFilter } from '@directus/sdk';
import set from 'lodash/set';
import { MRT_Localization_EN } from 'material-react-table/locales/en';
import { MRT_Localization_FR } from 'material-react-table/locales/fr';
import { CustomDirectusTypes } from 'shared/model/types';

//define re-useable default table options for all tables in your app
export const useGetDefaultMRTOptions = <TData extends MRT_RowData>(): Partial<
  MRT_TableOptions<TData>
> => {
  const currentLocal = useCurrentLanguage();

  let localization = MRT_Localization_EN;
  if (currentLocal.includes('fr')) {
    localization = MRT_Localization_FR;
  }

  return {
    getRowId: originalRow => originalRow.id + '',
    layoutMode: 'grid-no-grow',
    enableGlobalFilter: false,
    enableRowSelection: true,
    enableColumnFilterModes: true,
    initialState: {
      density: 'compact',
      showColumnFilters: false,
    },
    manualFiltering: false,
    manualPagination: false,
    manualSorting: false,
    localization,
    muiTableHeadCellProps: {
      //sx: { fontSize: '1rem' },
    },
    positionToolbarAlertBanner: 'bottom',
    //paginationDisplayMode: 'default',
    defaultColumn: {
      //you can even list default column options here
    },
    filterFns: {
      dateExists: (row, id, filterValue) => {
        return !!row.original[id];
      },
      dateNotExists: (row, id, filterValue) => {
        return !!!row.original[id];
      },
    },
  };
};

export const getDirectusSort = (sortState: MRT_SortingState): any[] => {
  return sortState.map(sort => {
    return sort.desc ? `-${sort.id}` : sort.id;
  });
};

export const useDirectusFilter = <TData extends MRT_RowData>(
  columnFilters: MRT_ColumnFiltersState,
  columnsDef: MRT_ColumnDef<TData, any>[],
): any => {
  const dFilters = columnFilters.reduce(
    (
      acc:
        | IfAny<CustomDirectusTypes, Record<string, any>, QueryFilter<CustomDirectusTypes, TData>>
        | undefined,
      current,
    ) => {
      const column = columnsDef.find(col => col.id === current.id);
      console.log(column);
      let operator = '_contains';
      if (column?.filterVariant) {
        if (column.filterVariant === 'select') {
          operator = '_eq';
        }
      }

      //@ts-ignore
      const filterPath = column?.meta?.filterPath ?? current.id;
      const filterValue = {
        [operator]: current.value,
      };
      //@ts-ignore
      set(acc, filterPath, filterValue);

      return acc;
    },
    {},
  );
  return dFilters;
};

import { Box, Grid2 as Grid } from '@mui/material';
import { useGetStructure } from 'modules/sheet/sheetHook';
import StructureScientificAttachmentsList from 'modules/sheet/structure/StructureScientificAttachmentsList';
import StructureTutelleList from 'modules/sheet/structure/StructureTutelleList';

const StructureMainTab = () => {
  const { data } = useGetStructure();

  if (!data) {
    return null;
  }

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid
          size={{
            xs: 12,
            lg: 6,
          }}
        >
          <StructureScientificAttachmentsList structure={data} />
        </Grid>
        <Grid
          size={{
            xs: 12,
            lg: 6,
          }}
        >
          <StructureTutelleList structure={data} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default StructureMainTab;

import get from 'lodash/get';

export const arrayRotate = (arr: any[], reverse = false) => {
  const result = [...arr];
  if (reverse) result.unshift(result.pop());
  else result.push(result.shift());
  return result;
};

export const sortBy = (fieldName: string) => (o1: object, o2: object) => {
  const str1 = get(o1, fieldName)?.toLowerCase();
  const str2 = get(o2, fieldName)?.toLowerCase();
  return str1.localeCompare(str2);
};

export const sortByName = () => sortBy('name');
export const sortByLabel = () => sortBy('label');

export const sortByDate = (fieldName: string) => (o1: any, o2: any) => {
  const d1 = new Date(o1[fieldName]);
  const d2 = new Date(o2[fieldName]);
  return d2.getTime() - d1.getTime();
};

export const sortString = (s1: string, s2: string) => {
  const l1 = s1.toLowerCase();
  const l2 = s2.toLowerCase();
  return l1.localeCompare(l2);
};

export const sortByNumber = (fieldName: string) => (o1: object, o2: object) => {
  const str1 = get(o1, fieldName);
  const str2 = get(o2, fieldName);
  return str1 - str2;
};

export const reorder = <T>(list: T[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export interface LabelValue {
  label: string;
  value: any;
}

export interface LabelId {
  label: string;
  id: any;
}

import { createItem, deleteItems, readItem, readItems, updateItem } from '@directus/sdk';

import directus, { MutationQuery, RequestQuery } from 'config/directus';
import api from 'services/api';
import { Job } from 'shared/model/types';

export const extendedApi = api
  .enhanceEndpoints({
    addTagTypes: ['job', 'jobs'],
  })
  .injectEndpoints({
    overrideExisting: true,
    endpoints: builder => ({
      getJobs: builder.query<Job[], RequestQuery<Job>>({
        queryFn: async q => {
          try {
            const data = await directus.request<Job[]>(readItems('job', q));
            return { data };
          } catch (error: any) {
            return { error };
          }
        },
        providesTags: ['jobs'],
      }),
      getJob: builder.query<Job, { id: string; q?: RequestQuery<Job> }>({
        queryFn: async q => {
          try {
            const data = await directus.request<Job>(readItem('job', q.id, q.q));
            return { data };
          } catch (error: any) {
            return { error };
          }
        },
        providesTags: ['job'],
      }),
      createJob: builder.mutation<
        Job,
        {
          item: Partial<Job>;
          query?: MutationQuery<Job>;
        }
      >({
        queryFn: async q => {
          try {
            const data = await directus.request<Job>(createItem('job', q.item, q.query));
            return { data };
          } catch (error: any) {
            return { error };
          }
        },
        invalidatesTags: ['job', 'jobs'],
      }),
      updateJob: builder.mutation<
        Job,
        {
          id: string;
          item: Partial<Job>;
          query?: MutationQuery<Job>;
        }
      >({
        queryFn: async q => {
          try {
            const data = await directus.request<Job>(updateItem('job', q.id, q.item, q.query));
            return { data };
          } catch (error: any) {
            return { error };
          }
        },
        invalidatesTags: ['job', 'jobs'],
      }),
      deleteJob: builder.mutation<void, Job[]>({
        queryFn: async job => {
          try {
            const ids = job.map(item => item.id);
            await directus.request(deleteItems('job', ids));
            return {
              data: undefined,
            };
          } catch (error: any) {
            return { error };
          }
        },
        invalidatesTags: ['job', 'jobs'],
      }),
    }),
  });

export const {
  useGetJobsQuery,
  useGetJobQuery,
  useCreateJobMutation,
  useUpdateJobMutation,
  useDeleteJobMutation,
} = extendedApi;

import { Helmet } from 'react-helmet-async';
import { useDirectusAppLogoUrl } from 'shared/hooks/directus-hook';

const AppHelmet = () => {
  const iconHref = useDirectusAppLogoUrl();
  return (
    <Helmet>
      <link rel="icon" type="image/svg+xml" href={iconHref} />
    </Helmet>
  );
};

export default AppHelmet;

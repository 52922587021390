import { createItem, deleteItems, readItem, readItems, updateItem } from '@directus/sdk';

import directus, { MutationQuery, RequestQuery } from 'config/directus';
import { teamApi } from 'services/endpoints/TeamEndpoints';
import { TeamMember } from 'shared/model/types';

export const extendedApi = teamApi
  .enhanceEndpoints({
    addTagTypes: ['teamMember', 'teamMembers'],
  })
  .injectEndpoints({
    overrideExisting: true,
    endpoints: builder => ({
      getTeamMembers: builder.query<TeamMember[], RequestQuery<TeamMember>>({
        queryFn: async q => {
          try {
            const data = await directus.request<TeamMember[]>(readItems('TeamMember', q));
            return { data };
          } catch (error: any) {
            return { error };
          }
        },
        providesTags: ['teamMembers'],
      }),
      getTeamMember: builder.query<TeamMember, { id: string; q?: RequestQuery<TeamMember> }>({
        queryFn: async q => {
          try {
            const data = await directus.request<TeamMember>(readItem('TeamMember', q.id, q.q));
            return { data };
          } catch (error: any) {
            return { error };
          }
        },
        providesTags: ['teamMember'],
      }),
      createTeamMember: builder.mutation<
        TeamMember,
        {
          item: Partial<TeamMember>;
          query?: MutationQuery<TeamMember>;
        }
      >({
        queryFn: async q => {
          try {
            const data = await directus.request<TeamMember>(
              createItem('TeamMember', q.item, q.query),
            );
            return { data };
          } catch (error: any) {
            return { error };
          }
        },
        invalidatesTags: ['teamMember', 'teamMembers'],
      }),
      updateTeamMember: builder.mutation<
        TeamMember,
        {
          id: string | number;
          item: Partial<TeamMember>;
          query?: MutationQuery<TeamMember>;
        }
      >({
        queryFn: async q => {
          try {
            const data = await directus.request<TeamMember>(
              updateItem('TeamMember', q.id, q.item, q.query),
            );
            return { data };
          } catch (error: any) {
            return { error };
          }
        },
        invalidatesTags: ['teamMember', 'teamMembers'],
      }),
      deleteTeamMember: builder.mutation<void, TeamMember[]>({
        queryFn: async teamMember => {
          try {
            const ids = teamMember.map(item => item.id);
            await directus.request(deleteItems('TeamMember', ids));

            return {
              data: undefined,
            };
          } catch (error: any) {
            return { error };
          }
        },
        invalidatesTags: ['teamMember', 'teamMembers'],
      }),
    }),
  });

export const {
  useGetTeamMembersQuery,
  useGetTeamMemberQuery,
  useCreateTeamMemberMutation,
  useUpdateTeamMemberMutation,
  useDeleteTeamMemberMutation,
} = extendedApi;

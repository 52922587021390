import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { IconButton, TextField, TextFieldProps } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import React, { useState } from 'react';

type Props = TextFieldProps & {
  innerRef?: React.Ref<HTMLInputElement>;
};

const CustomPasswordTextField = ({ innerRef, value, ...rest }: Props) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword(show => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <TextField
      ref={innerRef}
      {...rest}
      type={showPassword ? 'text' : 'password'}
      slotProps={{
        input: {
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        },
      }}
    />
  );
};

const PasswordTextField = React.forwardRef<HTMLInputElement, Props>((props, ref) => (
  <CustomPasswordTextField innerRef={ref} {...props} />
));

export default PasswordTextField;

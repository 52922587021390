import Box from '@mui/material/Box';
import InviteUserForm from 'modules/admin/users/InviteUserForm';
import { useGetRolesQuery } from 'services/endpoints/DirectusEndpoints';

const InviteUserPage = () => {
  const { data } = useGetRolesQuery();
  const roles = data ?? [];

  return <Box>{roles.length > 0 && <InviteUserForm roles={roles} />}</Box>;
};

export default InviteUserPage;

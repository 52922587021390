import { createItem, deleteItems, readItem, readItems, updateItem } from '@directus/sdk';

import directus, { MutationQuery, RequestQuery } from 'config/directus';
import { teamApi } from 'services/endpoints/TeamEndpoints';
import { ScientificAttachment } from 'shared/model/types';

export const extendedApi = teamApi
  .enhanceEndpoints({
    addTagTypes: ['ScientificAttachment', 'ScientificAttachments'],
  })
  .injectEndpoints({
    overrideExisting: true,
    endpoints: builder => ({
      getScientificAttachments: builder.query<
        ScientificAttachment[],
        RequestQuery<ScientificAttachment>
      >({
        queryFn: async q => {
          try {
            const data = await directus.request<ScientificAttachment[]>(
              readItems('scientificAttachment', q),
            );
            return { data };
          } catch (error: any) {
            return { error };
          }
        },
        providesTags: ['ScientificAttachments'],
      }),
      getScientificAttachment: builder.query<
        ScientificAttachment,
        { id: string; q?: RequestQuery<ScientificAttachment> }
      >({
        queryFn: async q => {
          try {
            const data = await directus.request<ScientificAttachment>(
              readItem('scientificAttachment', q.id, q.q),
            );
            return { data };
          } catch (error: any) {
            return { error };
          }
        },
        providesTags: ['ScientificAttachment'],
      }),
      createScientificAttachment: builder.mutation<
        ScientificAttachment,
        {
          file: File;
          item: Partial<ScientificAttachment>;
          query?: MutationQuery<ScientificAttachment>;
        }
      >({
        queryFn: async q => {
          try {
            const data = await directus.request<ScientificAttachment>(
              createItem('scientificAttachment', q.item, q.query),
            );
            return { data };
          } catch (error: any) {
            return { error };
          }
        },
        invalidatesTags: ['ScientificAttachment', 'ScientificAttachments', 'team'],
      }),
      updateScientificAttachment: builder.mutation<
        ScientificAttachment,
        {
          id: string;
          item: Partial<ScientificAttachment>;
          query?: MutationQuery<ScientificAttachment>;
        }
      >({
        queryFn: async q => {
          try {
            const data = await directus.request<ScientificAttachment>(
              updateItem('scientificAttachment', q.id, q.item, q.query),
            );
            return { data };
          } catch (error: any) {
            return { error };
          }
        },
        invalidatesTags: ['ScientificAttachment', 'ScientificAttachments', 'team'],
      }),
      deleteScientificAttachment: builder.mutation<void, ScientificAttachment[]>({
        queryFn: async ScientificAttachment => {
          try {
            const ids = ScientificAttachment.map(item => item.id);
            await directus.request(deleteItems('scientificAttachment', ids));

            return {
              data: undefined,
            };
          } catch (error: any) {
            return { error };
          }
        },
        invalidatesTags: ['ScientificAttachment', 'ScientificAttachments', 'team'],
      }),
    }),
  });

export const {
  useGetScientificAttachmentsQuery,
  useGetScientificAttachmentQuery,
  useCreateScientificAttachmentMutation,
  useUpdateScientificAttachmentMutation,
  useDeleteScientificAttachmentMutation,
} = extendedApi;

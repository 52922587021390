import isNumber from 'lodash/isNumber';
import { useEffect } from 'react';
import {
  useCreateUserHistoryMutation,
  useGetUserHistoriesQuery,
  useGetUserHistoryQuery,
  useUpdateUserHistoryMutation,
} from 'services/endpoints/UserHistoryEndpoints';
import { useCurrentUserId } from 'shared/hooks/auth-hook';

export const useGetHistories = (limit = 100) => {
  const userId = useCurrentUserId();

  const { data } = useGetUserHistoriesQuery(
    {
      limit,
      filter: {
        user: userId,
      },
      sort: ['-date_updated'],
    },
    {
      skip: !userId,
    },
  );

  return data ?? [];
};

export const useSaveHistory = (
  type: 'team' | 'person' | 'structure',
  objectId: number | undefined,
  name: string | null | undefined,
) => {
  const [createHistory] = useCreateUserHistoryMutation();
  const [updateHistory] = useUpdateUserHistoryMutation();

  const userId = useCurrentUserId();

  const { data, isSuccess } = useGetUserHistoryQuery(
    {
      filter: {
        user: userId,
        type: {
          _eq: type,
        },
        objectId: {
          _eq: objectId,
        },
      },
    },
    {
      skip: !objectId || !userId,
    },
  );

  const histories = data ?? [];

  useEffect(() => {
    if (userId && type && isNumber(objectId) && name && isSuccess) {
      const now = new Date().toISOString();
      const existingHistory = histories.length > 0 ? histories[0] : null;
      if (existingHistory) {
        updateHistory({
          id: existingHistory.id,
          item: {
            ...existingHistory,
            date_updated: now,
          },
        });
      } else {
        createHistory({
          item: {
            name,
            type,
            objectId,
            user: userId,
            date_updated: now,
          },
        });
      }
    }
  }, [type, objectId, isSuccess]);
};

import { readItems } from '@directus/sdk';
import directus, { RequestQuery } from 'config/directus';
import api from 'services/api';
import { Metier } from 'shared/model/types';

const metierApi = api
  .enhanceEndpoints({
    addTagTypes: ['metiers', 'metier'],
  })
  .injectEndpoints({
    overrideExisting: true,
    endpoints: builder => ({
      getMetiers: builder.query<Metier[], RequestQuery<Metier>>({
        queryFn: async q => {
          try {
            const data = await directus.request<Metier[]>(readItems('Metier', q));
            return { data };
          } catch (error: any) {
            return { error };
          }
        },
        providesTags: ['metiers'],
      }),
    }),
  });

export const { useGetMetiersQuery } = metierApi;

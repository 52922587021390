import {
  AggregationsStringTermsAggregate,
  SearchResponse,
} from '@elastic/elasticsearch/lib/api/types';
import SearchFacets from 'modules/search/SearchFacets';
import { Scrollbars } from 'react-custom-scrollbars';
import { useTranslation } from 'react-i18next';
import Loader from 'shared/components/Loader';

type Props = {
  isLoading: boolean;
  response?: SearchResponse<any>;
};

const RootSearchFacets = ({ response, isLoading }: Props) => {
  const { t } = useTranslation();
  const aggregations = response?.aggregations;

  const indexFacets = aggregations?._index;

  if (isLoading) {
    return <Loader />;
  }

  if (indexFacets) {
    const indexTermsFacets = indexFacets as AggregationsStringTermsAggregate;

    return (
      <Scrollbars className="search-facets" autoHide autoHeight autoHeightMax="calc(100vh - 140px)">
        <SearchFacets label={t('types')} agg={indexTermsFacets} disabled={isLoading} />
      </Scrollbars>
    );
  }

  return null;
};

export default RootSearchFacets;

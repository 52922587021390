import { SearchHit } from '@elastic/elasticsearch/lib/api/types';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Box, IconButton, ListItemAvatar, ListItemButton, ListItemText } from '@mui/material';
import SearchResultDetails from 'modules/search/results/SearchResultDetails';
import { Link, useNavigate } from 'react-router-dom';
import ModelIcon from 'shared/model/def/ModelIcon';
import ModelName from 'shared/model/def/ModelName';
import { disablePropagation } from 'shared/utils/event-utils';

type Props = {
  item: SearchHit<any>;
};

const SearchResultItem = ({ item }: Props) => {
  const navigate = useNavigate();

  const index = item._index as 'team' | 'structure' | 'person';

  const url = `/sheet/${index}/${item._id}/main`;

  const onClick = () => {
    navigate(url);
  };

  return (
    <ListItemButton onClick={onClick} alignItems="flex-start" className="search-result-item">
      <ListItemAvatar>
        <ModelIcon model={index} asAvatar fontSize="large" />
      </ListItemAvatar>
      <ListItemText
        primary={
          <Box mb={0.5} display="flex" alignItems="center">
            <ModelName model={item._source} type={item._index} />
            <IconButton
              size="small"
              color="primary"
              component={Link}
              to={url}
              target="_blank"
              rel="noopener noreferrer"
              onClick={disablePropagation}
            >
              <OpenInNewIcon />
            </IconButton>
          </Box>
        }
        secondary={<SearchResultDetails model={item._source} type={item._index} />}
        secondaryTypographyProps={{ component: 'div' }}
      />
    </ListItemButton>
  );
};

export default SearchResultItem;

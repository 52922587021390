import { createItem, deleteItems, readItem, readItems, updateItem } from '@directus/sdk';

import directus, { MutationQuery, RequestQuery } from 'config/directus';
import api from 'services/api';
import { StructureType } from 'shared/model/types';

export const extendedApi = api
  .enhanceEndpoints({
    addTagTypes: ['StructureType', 'StructureTypes'],
  })
  .injectEndpoints({
    overrideExisting: true,
    endpoints: builder => ({
      getStructureTypes: builder.query<StructureType[], RequestQuery<StructureType>>({
        queryFn: async q => {
          try {
            const data = await directus.request<StructureType[]>(readItems('structureType', q));
            return { data };
          } catch (error: any) {
            return { error };
          }
        },
        providesTags: ['StructureTypes'],
      }),
      getStructureType: builder.query<
        StructureType,
        { id: string; q?: RequestQuery<StructureType> }
      >({
        queryFn: async q => {
          try {
            const data = await directus.request<StructureType>(
              readItem('structureType', q.id, q.q),
            );
            return { data };
          } catch (error: any) {
            return { error };
          }
        },
        providesTags: ['StructureType'],
      }),
      createStructureType: builder.mutation<
        StructureType,
        {
          item: Partial<StructureType>;
          query?: MutationQuery<StructureType>;
        }
      >({
        queryFn: async q => {
          try {
            const data = await directus.request<StructureType>(
              createItem('structureType', q.item, q.query),
            );
            return { data };
          } catch (error: any) {
            return { error };
          }
        },
        invalidatesTags: ['StructureType', 'StructureTypes'],
      }),
      updateStructureType: builder.mutation<
        StructureType,
        {
          id: string;
          item: Partial<StructureType>;
          query?: MutationQuery<StructureType>;
        }
      >({
        queryFn: async q => {
          try {
            const data = await directus.request<StructureType>(
              updateItem('structureType', q.id, q.item, q.query),
            );
            return { data };
          } catch (error: any) {
            return { error };
          }
        },
        invalidatesTags: ['StructureType', 'StructureTypes'],
      }),
      deleteStructureType: builder.mutation<void, StructureType[]>({
        queryFn: async StructureType => {
          try {
            const ids = StructureType.map(item => item.id);
            await directus.request(deleteItems('structureType', ids));
            return {
              data: undefined,
            };
          } catch (error: any) {
            return { error };
          }
        },
        invalidatesTags: ['StructureType', 'StructureTypes'],
      }),
    }),
  });

export const {
  useGetStructureTypesQuery,
  useGetStructureTypeQuery,
  useCreateStructureTypeMutation,
  useUpdateStructureTypeMutation,
  useDeleteStructureTypeMutation,
} = extendedApi;

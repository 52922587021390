import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { useMatch, useNavigate } from 'react-router-dom';

const SearchHeaderMenu = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const match = useMatch('/search');

  const onClick = () => {
    navigate('/search');
  };

  return (
    <Button
      variant="text"
      sx={{ color: match ? '#FFF' : '#aaa' }}
      onClick={onClick}
      startIcon={<SearchIcon />}
    >
      {t('search')}
    </Button>
  );
};

export default SearchHeaderMenu;

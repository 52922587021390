import DOMPurify from 'dompurify';
import { stringHtmlHasContent } from 'shared/utils/string-utils';

type Props = {
  html: string;
  frame?: boolean;
};

const DisplayHtml = ({ html, frame }: Props) => {
  const safeHtml = DOMPurify.sanitize(html);

  if (!stringHtmlHasContent(safeHtml)) {
    return null;
  }

  if (frame) {
    return (
      <iframe>
        <span dangerouslySetInnerHTML={{ __html: safeHtml }}></span>
      </iframe>
    );
  }

  return <span dangerouslySetInnerHTML={{ __html: safeHtml }}></span>;
};

export default DisplayHtml;

import { SearchResponse, SearchTotalHits } from '@elastic/elasticsearch/lib/api/types';
import { Box, List, ListItem, Pagination } from '@mui/material';

import SearchResultBar from 'modules/search/results/SearchResultBar';
import SearchResultItem from 'modules/search/results/SearchResultItem';
import { useEffect, useRef } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { setFrom } from 'shared/reducers/searchSlice';
import { useAppDispatch, useAppSelector } from 'shared/redux/redux-utils';

type Props = {
  isLoading: boolean;
  response?: SearchResponse<any>;
};

const SearchResult = ({ response, isLoading }: Props) => {
  const dispatch = useAppDispatch();
  const ref = useRef<HTMLUListElement | null>(null);
  const size = useAppSelector(state => state.search.size);
  const from = useAppSelector(state => state.search.from);

  const results = response?.hits.hits ?? [];

  const totalHits = response?.hits.total as SearchTotalHits | undefined;
  const total = totalHits?.value ?? 0;

  const currentPage = Math.floor(from / size) + 1;
  const countPageSize = Math.floor(total / size);

  const scrollToTop = () => {
    ref.current?.scroll({
      top: 0,
    });
  };

  useEffect(() => {
    scrollToTop();
  }, [response]);

  const loadMore = (event: React.ChangeEvent<unknown>, pageNumber: number) => {
    dispatch(setFrom((pageNumber - 1) * size));
  };

  return (
    <Box>
      <SearchResultBar response={response} />
      <Scrollbars autoHide autoHeight autoHeightMax="calc(100vh - 180px)">
        <List id="search-result-list" ref={ref}>
          {results.map(result => (
            <SearchResultItem key={result._index + '_' + result._id} item={result} />
          ))}
          <ListItem
            sx={{
              justifyContent: 'center',
            }}
          >
            <Pagination
              count={countPageSize}
              color="primary"
              page={currentPage}
              onChange={loadMore}
              disabled={isLoading}
            />
          </ListItem>
        </List>
      </Scrollbars>
    </Box>
  );
};

export default SearchResult;

import ListItemIcon from '@mui/material/ListItemIcon/ListItemIcon';
import ListItemText from '@mui/material/ListItemText/ListItemText';
import MenuItem from '@mui/material/MenuItem/MenuItem';
import { ReactNode } from 'react';

const HeaderMenuItem = ({ text, icon, onClick }: { text: string; icon: ReactNode; onClick: () => void }) => {
  return (
    <MenuItem onClick={onClick}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText>{text}</ListItemText>
    </MenuItem>
  );
};

export default HeaderMenuItem;

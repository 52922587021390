import GroupsIcon from '@mui/icons-material/Groups';
import { Team } from 'shared/model/types';

export const TeamIcon = GroupsIcon;

export const getTeamAdress = (team?: Team) => {
  if (!team) {
    return '';
  }

  let result = '';
  if (team.adresse) {
    result += team.adresse;
  }
  if (team.adresse_2) {
    result += ' ' + team.adresse_2;
  }
  if (team.cp) {
    result += ' ' + team.cp;
  }
  if (team.city) {
    result += ' ' + team.city;
  }
  return result;
};

export type TeamFormType = Omit<
  Team,
  'id' | 'tutelles' | 'members' | 'scientific_attachment' | 'description' | 'privateDescription'
>;

import { Grid2 as Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { ReactNode } from 'react';
import { FieldErrors } from 'react-hook-form';
import { showErrors } from 'shared/components/form/form-utils';
import FormDebugError from 'shared/components/form/FormDebugError';
import PageTitle from 'shared/components/PageTitle';

type Props = {
  titlei18n?: string;
  text?: string;
  icon: ReactNode;
  errors?: FieldErrors<object>;
  children?: React.ReactNode;
  preview?: React.ReactNode;
  fullWidth?: boolean;
};

const FormTemplate = (props: Props) => {
  const { titlei18n, text, icon, errors, preview, fullWidth = false, children } = props;

  const md = fullWidth ? 12 : 8;
  const lg = fullWidth ? 12 : 6;
  const xl = fullWidth ? 12 : 4;

  return (
    <Grid container justifyContent="space-around" alignItems="center" spacing={2}>
      <Grid
        size={{
          xs: 12,
          md: md,
          lg: lg,
          xl: xl,
        }}
      >
        <Paper sx={{ padding: 2 }}>
          <PageTitle text={text} i18n={titlei18n} icon={icon} />
          <Box mt={2}>{children}</Box>
        </Paper>
      </Grid>
      {showErrors(errors) && (
        <Grid>
          <FormDebugError errors={errors} />
        </Grid>
      )}
      {preview}
    </Grid>
  );
};

export default FormTemplate;

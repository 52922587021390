import { Outlet, RouteObject } from 'react-router-dom';
import PrivateRoute from 'shared/routes/privateRoute';
import { Role } from 'shared/types/role';

import PersonRoutes from 'modules/sheet/person';
import StructureRoutes from 'modules/sheet/structure';
import TeamRoutes from 'modules/sheet/team';

const routes: RouteObject[] = [
  {
    path: '/sheet',
    element: (
      <PrivateRoute roles={[Role.Admin]}>
        <Outlet />
      </PrivateRoute>
    ),
    children: [...PersonRoutes, ...TeamRoutes, ...StructureRoutes],
  },
];

export default routes;

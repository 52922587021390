import LoginPage from 'modules/user/login/LoginPage';
import { ReactNode } from 'react';
import { useIsAdmin, useIsAuthenticated } from 'shared/hooks/auth-hook';
import { Role } from 'shared/types/role';

interface IProps {
  children: ReactNode;
  roles?: Role[];
  exceptions?: string[];
}

const PrivateRoute = ({ children, roles = [] }: IProps) => {
  const authenticated = useIsAuthenticated();
  const isAdmin = useIsAdmin();

  if (!authenticated) {
    return <LoginPage />;
  }

  const userRoles = [];
  if (isAdmin) {
    userRoles.push(Role.Admin);
  } else {
    userRoles.push(Role.User);
  }

  if (roles.length > 0) {
    const hasRole = userRoles.some(userRole => roles.includes(userRole));
    if (hasRole) {
      return children;
    }
    return <LoginPage />;
  }

  return children;
};

export default PrivateRoute;

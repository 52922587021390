import { useUpdateTeamMutation } from 'services/endpoints/TeamEndpoints';
import ConfirmDelete from 'shared/components/confirmDelete/ConfirmDelete';
import { Team } from 'shared/model/types';

type Props = {
  teams: Team[];
};

const DeleteTeamFromStructure = ({ teams }: Props) => {
  const [updateTeam] = useUpdateTeamMutation();

  const onConfirm = async () => {
    for (const team of teams) {
      await updateTeam({
        id: team.id,
        item: {
          structure: null,
        },
      }).unwrap();
    }
  };

  return <ConfirmDelete onConfirm={onConfirm} />;
};

export default DeleteTeamFromStructure;

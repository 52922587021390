// check if the html has text content
//see https://developer.mozilla.org/fr/docs/Web/API/Node/nodeType
export const stringHtmlHasContent = (str: string) => {
  var doc = new DOMParser().parseFromString(str, 'text/html');
  return Array.from(doc.body.childNodes).some(node => childNodeHasContent(node));
};

export const childNodeHasContent = (node: ChildNode) => {
  return node.nodeType === 3 || Array.from(node.childNodes).some(childNodeHasContent);
};

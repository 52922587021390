import { Autocomplete, Box, TextField } from '@mui/material';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import isNumber from 'lodash/isNumber';
import { useState } from 'react';
import { SyntheticEvent } from 'react-draft-wysiwyg';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'react-use';
import { useGetStructuresQuery } from 'services/endpoints/StructureEndpoints';
import { Structure } from 'shared/model/types';

type Props = {
  value: Structure | number | null;
  label?: string;
  onChange: (newValue: Structure | null) => void;
  error?: boolean;
  helperText?: string;
  disabled?: boolean;
};

const StructureAutocomplete = ({ value, onChange, error, helperText, disabled, label }: Props) => {
  const { t } = useTranslation();

  const [inputValue, setInputValue] = useState('');
  const [debouncedValue, setDebouncedValue] = useState('');

  const [, cancel] = useDebounce(
    () => {
      setDebouncedValue(inputValue);
    },
    500,
    [inputValue],
  );

  const { data, isLoading } = useGetStructuresQuery(
    {
      fields: ['id', 'name'],
      sort: ['name'],
      filter: {
        name: { _icontains: `%${debouncedValue}%` },
      },
    },
    {
      skip: !debouncedValue,
    },
  );

  const onSelection = (e: SyntheticEvent, value: Structure | null) => {
    onChange(value);
  };

  const options = data || [];

  const finalLabel = label ?? t('structure.search');

  const finalValue = isNumber(value) ? options.find(x => x.id === value) : value;

  return (
    <Autocomplete
      autoComplete
      value={finalValue ?? null}
      getOptionLabel={opt => opt.name ?? ''}
      getOptionKey={opt => opt.id}
      disabled={disabled}
      renderInput={params => (
        <TextField
          {...params}
          label={finalLabel}
          fullWidth
          disabled={disabled}
          error={error}
          helperText={helperText}
        />
      )}
      options={options}
      loading={isLoading}
      filterOptions={x => x}
      onChange={onSelection}
      includeInputInList
      noOptionsText={t('person.noResults')}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderOption={(props, option) => {
        const { key, ...optionProps } = props;

        const optionToSearch = option.name ?? '';

        const matches = match(optionToSearch, inputValue, { insideWords: true });
        const parts = parse(optionToSearch, matches);

        return (
          <li key={key} {...optionProps}>
            {parts.map((part, index) => (
              <Box
                key={index}
                component="span"
                sx={{
                  fontWeight: part.highlight ? 'bold' : 'regular',
                  whiteSpace: 'pre-wrap',
                }}
              >
                {part.text}
              </Box>
            ))}
          </li>
        );
      }}
    ></Autocomplete>
  );
};

export default StructureAutocomplete;

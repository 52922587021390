import { Box } from '@mui/material';
import React from 'react';

type Props = {
  value: number;
  index: number;
  children?: React.ReactNode;
};

const TabContent = ({ value, index, children }: Props) => {
  return <Box>{index === value && <Box p={2}>{children}</Box>}</Box>;
};

export default TabContent;

import TeamMainTab from 'modules/sheet/team/TeamMainTab';
import TeamMembersTab from 'modules/sheet/team/TeamMembersTab';
import TeamPresentationTab from 'modules/sheet/team/TeamPresentationTab';
import TeamSheet from 'modules/sheet/team/TeamSheet';
import { Outlet, RouteObject } from 'react-router-dom';

const routes: RouteObject[] = [
  {
    path: 'team/:id',
    element: (
      <TeamSheet>
        <Outlet />
      </TeamSheet>
    ),
    children: [
      {
        path: 'main',
        element: <TeamMainTab />,
      },
      {
        path: 'presentation',
        element: <TeamPresentationTab />,
      },
      {
        path: 'members',
        element: <TeamMembersTab />,
      },
    ],
  },
];

export default routes;

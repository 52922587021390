import { Box, Grid } from '@mui/material';
import { useGetTeam } from 'modules/sheet/sheetHook';
import { useTranslation } from 'react-i18next';
import { useUpdateTeamMutation } from 'services/endpoints/TeamEndpoints';
import EditableCartTextEditor from 'shared/components/Card/EditableCartTextEditor';

const TeamPresentationTab = () => {
  const { t } = useTranslation();
  const { data } = useGetTeam();

  const [updateTeam, { isLoading }] = useUpdateTeamMutation();

  if (!data) {
    return null;
  }

  const onShortDescriptionChange = async (content: string) => {
    return updateTeam({
      id: data.id,
      item: {
        shortDescription: content,
      },
    });
  };

  const onLongDescriptionChange = async (content: string) => {
    return updateTeam({
      id: data.id,
      item: {
        longDescription: content,
      },
    });
  };

  const onPrivateDescriptionChange = async (content: string) => {
    return updateTeam({
      id: data.id,
      item: {
        privateDescription: content,
      },
    });
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={6} xl={4}>
          <EditableCartTextEditor
            title={t('shortDescription')}
            content={data.shortDescription}
            onValid={onShortDescriptionChange}
            disabled={isLoading}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={6} xl={4}>
          <EditableCartTextEditor
            title={t('description')}
            content={data.longDescription}
            onValid={onLongDescriptionChange}
            disabled={isLoading}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={6} xl={4}>
          <EditableCartTextEditor
            title={t('privateDescription')}
            content={data.privateDescription}
            onValid={onPrivateDescriptionChange}
            disabled={isLoading}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default TeamPresentationTab;

import SearchIcon from '@mui/icons-material/Search';
import { Box, IconButton, TextField } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useLazySearchQuery } from 'services/searchApi';
import Loader from 'shared/components/Loader';
import { EscapeSearch } from 'shared/types/EscapeSearch.model';

const SearchHomeBar = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchText, setsearchText] = useState('');

  const [makeSearch, { isLoading }] = useLazySearchQuery();

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setsearchText(e.target.value);
  };

  const search = async () => {
    if (searchText && searchText.length > 0) {
      const newSearch: EscapeSearch = {
        index: '',
        search: {
          from: 0,
          size: 20,
          query: {
            bool: {
              must: [
                {
                  query_string: {
                    query: searchText,
                  },
                },
              ],
            },
          },
        },
      };
      await makeSearch(newSearch).unwrap();

      navigate('/search');
    }
  };

  const onClick = () => {
    search();
  };

  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key == 'Enter') {
      e.preventDefault();
      search();
    }
  };

  return (
    <TextField
      hiddenLabel
      value={searchText ?? ''}
      size="small"
      onChange={onChange}
      onKeyDown={onKeyPress}
      placeholder={t('searchPage.placeholder')}
      style={{
        maxWidth: '450px',
      }}
      disabled={isLoading}
      slotProps={{
        input: {
          endAdornment: (
            <>
              {!isLoading ? (
                <IconButton type="button" sx={{ px: 0.8 }} onClick={onClick}>
                  <SearchIcon />
                </IconButton>
              ) : (
                <Box pt={0.5}>
                  <Loader size="1rem" delay={0} />
                </Box>
              )}
            </>
          ),
        },
      }}
    />
  );
};

export default SearchHomeBar;

import { useParams } from 'react-router-dom';
import { useGetPersonQuery } from 'services/endpoints/PersonEndpoints';
import { useGetStructureQuery } from 'services/endpoints/StructureEndpoints';
import { useGetTeamQuery } from 'services/endpoints/TeamEndpoints';
import { useGetTeamMembersQuery } from 'services/endpoints/TeamMemberEndpoints';

export const useGetPerson = () => {
  const { id } = useParams<{ id: string }>();
  return useGetPersonQuery(
    {
      id: id as string,
      q: {
        fields: [
          '*',
          // @ts-ignore
          'teamMembers.*.*',
          // @ts-ignore
          'scientificAttachment.scientificAttachment_id.*',
          // @ts-ignore
          'jobs.*',
        ],
      },
    },
    {
      skip: !id,
    },
  );
};

export const useGetTeam = () => {
  const { id } = useParams<{ id: string }>();
  return useGetTeamQuery(
    {
      id: id as string,
      q: {
        fields: [
          '*',
          // @ts-ignore
          'person.*',
          // @ts-ignore
          'type.*',
          // @ts-ignore
          'geosite.*',
          // @ts-ignore
          'scientific_attachment.scientificAttachment_id.*',
          // @ts-ignore
          'tutelles.*',
          // @ts-ignore
          'tutelles.organization.*',
          // @ts-ignore
          'responsibleFunction.*',
          // @ts-ignore
          'structure.id',
          // @ts-ignore
          'structure.name',
        ],
      },
    },
    {
      skip: !id,
    },
  );
};

export const useGetTeamMembers = () => {
  const { id } = useParams<{ id: string }>();
  return useGetTeamMembersQuery(
    {
      filter: {
        team: {
          _eq: id,
        },
      },
      // @ts-ignore
      fields: ['*', 'person.*', 'metier.*.*', 'statut.*'],
    },
    {
      skip: !id,
    },
  );
};

export const useGetStructure = () => {
  const { id } = useParams<{ id: string }>();
  return useGetStructureQuery(
    {
      id: id as string,
      q: {
        fields: [
          '*',
          // @ts-ignore
          'person.*',
          // @ts-ignore
          'type.*',
          // @ts-ignore
          'geosite.*',
          // @ts-ignore
          'scientificAttachments.scientificAttachment_id.*',
          // @ts-ignore
          'responsible.*',
          // @ts-ignore
          'responsibleFunction.*',
          // @ts-ignore
          'tutelles.*',
          // @ts-ignore
          'tutelles.organization.*',
        ],
      },
    },
    {
      skip: !id,
    },
  );
};

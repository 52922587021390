import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  contentId: '',
  top: false,
  right: false,
  left: false,
  bottom: false,
};

export type DrawerState = Readonly<typeof initialState>;

export const slice = createSlice({
  name: 'drawer',
  initialState,
  reducers: {
    setTop: (state, action: PayloadAction<boolean>) => {
      state.top = action.payload;
    },
    setRight: (state, action: PayloadAction<boolean>) => {
      state.right = action.payload;
    },
    setLeft: (state, action: PayloadAction<boolean>) => {
      state.left = action.payload;
    },
    setBottom: (state, action: PayloadAction<boolean>) => {
      state.bottom = action.payload;
    },
    setContentId: (state, action: PayloadAction<string>) => {
      state.contentId = action.payload;
    },
    reset: state => {
      state.contentId = '';
      state.top = false;
      state.right = false;
      state.left = false;
      state.bottom = false;
    },
  },
});

export const { reset, setLeft, setBottom, setRight, setTop, setContentId } = slice.actions;

export const openDrawer =
  (anchor: 'top' | 'left' | 'bottom' | 'right', id: string) => async (dispatch: any) => {
    dispatch(setContentId(id));
    //@ts-ignore
    dispatch(slice.actions[`set${anchor.charAt(0).toUpperCase() + anchor.slice(1)}`](true));
  };

export const closeDrawer =
  (anchor: 'top' | 'left' | 'bottom' | 'right') => async (dispatch: any) => {
    dispatch(setContentId(''));
    //@ts-ignore
    dispatch(slice.actions[`set${anchor.charAt(0).toUpperCase() + anchor.slice(1)}`](false));
  };

export const closeAllDrawers = () => async (dispatch: any) => {
  dispatch(reset());
};

export default slice.reducer;

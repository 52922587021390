import { DirectusSettings, readOpenApiSpec, readRoles, readSettings } from '@directus/sdk';
import directus from 'config/directus';
import api from 'services/api';
import { CustomDirectusTypes, DirectusRoles } from 'shared/model/types';

const keepUnusedDataFor = 3600;

const serverInfoApi = api
  .enhanceEndpoints({
    addTagTypes: ['serverInfo', 'oas', 'roles'],
  })
  .injectEndpoints({
    overrideExisting: true,
    endpoints: builder => ({
      getServerInfo: builder.query<DirectusSettings<CustomDirectusTypes>, void>({
        queryFn: async () => {
          try {
            const data = (await directus.request(
              readSettings(),
            )) as DirectusSettings<CustomDirectusTypes>;
            return { data };
          } catch (error: any) {
            return { error };
          }
        },
        providesTags: ['serverInfo'],
        keepUnusedDataFor,
      }),
      getOas: builder.query<object, void>({
        queryFn: async () => {
          try {
            const data = await directus.request(readOpenApiSpec());
            return { data };
          } catch (error: any) {
            return { error };
          }
        },
        providesTags: ['oas'],
        keepUnusedDataFor,
      }),
      getRoles: builder.query<DirectusRoles[], void>({
        queryFn: async () => {
          try {
            const data = await directus.request(
              readRoles({
                sort: 'name',
              }),
            );
            return { data };
          } catch (error: any) {
            return { error };
          }
        },
        providesTags: ['roles'],
        keepUnusedDataFor,
      }),
    }),
  });

export const { useGetServerInfoQuery, useGetOasQuery, useGetRolesQuery } = serverInfoApi;

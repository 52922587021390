import { Box, Paper } from '@mui/material';
import isObject from 'lodash/isObject';
import {
  createMRTColumnHelper,
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import CreateStructureTeamBtn from 'modules/sheet/structure/teams/createStructureTeamBtn';
import DeleteTeamFromStructure from 'modules/sheet/structure/teams/DeleteTeamFromStructure';
import StructureSelectTeamBtn from 'modules/sheet/structure/teams/StructureSelectTeamsBtn';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { useGetTeamsQuery } from 'services/endpoints/TeamEndpoints';
import { useGetDefaultMRTOptions } from 'shared/material-data-table/defaultMRTable';
import { Team } from 'shared/model/types';

const columnHelper = createMRTColumnHelper<Team>();

const StructureTeamsTab = () => {
  const { t } = useTranslation();
  const defaultMRTOptions = useGetDefaultMRTOptions<Team>();
  const { id } = useParams<{ id: string }>();

  const columns = useMemo(() => {
    const result = [
      columnHelper.accessor('name', {
        header: t('name'),
        grow: 8,
        Cell({ cell, row }) {
          const value = cell.getValue();
          if (value && isObject(row.original)) {
            return <Link to={`/sheet/team/${row.original.id}/main`}>{value}</Link>;
          }
          return null;
        },
      }),
      columnHelper.accessor('shortName', {
        header: t('team.shortName'),
        grow: 1,
      }),
      columnHelper.accessor('type.name', {
        header: t('type'),
        grow: 1,
      }),
      columnHelper.accessor('email', {
        header: t('email'),
        grow: 1,
        Cell({ cell }) {
          const email = cell.getValue();
          if (email) {
            return <a href={`mailto:${email}`}>{email}</a>;
          }
          return null;
        },
      }),
      columnHelper.accessor('phone', {
        header: t('phone'),
        grow: 1,
      }),
      columnHelper.accessor('web_site', {
        header: t('webSite'),
        grow: 4,
        Cell({ cell }) {
          const webSite = cell.getValue();
          if (webSite) {
            return (
              <a href={webSite} target="_blank">
                {webSite}
              </a>
            );
          }
          return null;
        },
      }),
    ];
    return result;
  }, [t]);

  const { data, isLoading } = useGetTeamsQuery({
    filter: {
      structure: Number(id),
    },
    // @ts-ignore
    fields: ['*', 'type.*'],
    sort: ['name'],
  });

  const teams = data ?? [];

  const table = useMaterialReactTable({
    ...defaultMRTOptions,
    renderTopToolbarCustomActions: ({ table }) => {
      const selection = table.getSelectedRowModel().flatRows.map(row => row.original);

      return (
        <>
          <Box flexGrow={1}>
            {selection.length === 0 ? (
              <Box display="flex">
                <Box mr={1}>
                  <CreateStructureTeamBtn structureId={Number(id)} />
                </Box>
                <StructureSelectTeamBtn structureId={Number(id)} selected={teams} />
              </Box>
            ) : (
              selection.length > 0 && <DeleteTeamFromStructure teams={selection} />
            )}
          </Box>
        </>
      );
    },
    state: {
      isLoading,
      showProgressBars: isLoading,
    },
    initialState: {
      ...defaultMRTOptions.initialState,
    },
    columns,
    data: teams,
  });

  return (
    <Paper elevation={3}>
      <MaterialReactTable table={table} />
    </Paper>
  );
};

export default StructureTeamsTab;

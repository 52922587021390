import { Box } from '@mui/material';
import { ReactNode } from 'react';

type Props = {
  icon?: ReactNode;
  alignItems?: 'flex-start' | 'center' | 'flex-end' | 'stretch' | 'baseline';
  children: ReactNode;
};

const IconValue = ({ icon, alignItems = 'flex-start', children }: Props) => {
  return (
    <Box display="flex" alignItems={alignItems}>
      {icon && <Box mr={1}>{icon}</Box>}
      <Box>{children}</Box>
    </Box>
  );
};

export default IconValue;

import {
  DirectusUser,
  acceptUserInvite,
  deleteUsers,
  inviteUser,
  passwordRequest,
  passwordReset,
  readUsers,
} from '@directus/sdk';
import client, { RequestQuery } from 'config/directus';
import { ConfirmInvite, InviteUser } from 'modules/admin/users/InviteUser.model';
import { RequestResetPassword, ResetPassword } from 'modules/user/resetPwd/ResetPwd.model';
import api from 'services/api';
import { CustomDirectusTypes } from 'shared/model/types';

const extentedApi = api
  .enhanceEndpoints({
    addTagTypes: ['users'],
  })
  .injectEndpoints({
    overrideExisting: true,
    endpoints: builder => ({
      getUsers: builder.query<
        DirectusUser<CustomDirectusTypes>[],
        RequestQuery<DirectusUser<CustomDirectusTypes>>
      >({
        queryFn: async q => {
          try {
            const data = await client.request<DirectusUser<CustomDirectusTypes>[]>(readUsers(q));
            return { data };
          } catch (error: any) {
            return { error };
          }
        },
        providesTags: ['users'],
      }),
      deleteUsers: builder.mutation<void, string[]>({
        queryFn: async ids => {
          try {
            await client.request(deleteUsers(ids));
            return {
              data: undefined,
            };
          } catch (error: any) {
            return { error };
          }
        },
        invalidatesTags: ['users'],
      }),
      inviteUser: builder.mutation<void, InviteUser>({
        queryFn: async i => {
          try {
            await client.request(inviteUser(i.email, i.role, i.invite_url));
            return {
              data: undefined,
            };
          } catch (error: any) {
            return { error };
          }
        },
        invalidatesTags: ['users'],
      }),
      confirmInvite: builder.mutation<void, ConfirmInvite>({
        queryFn: async i => {
          try {
            await client.request(acceptUserInvite(i.token, i.password));
            return {
              data: undefined,
            };
          } catch (error: any) {
            return { error };
          }
        },
        invalidatesTags: ['users'],
      }),
      requestResetPassword: builder.mutation<void, RequestResetPassword>({
        queryFn: async rp => {
          try {
            await client.request(passwordRequest(rp.email, rp.resetUrl));
            return {
              data: undefined,
            };
          } catch (error: any) {
            return { error };
          }
        },
        invalidatesTags: ['users'],
      }),
      resetPassword: builder.mutation<void, ResetPassword>({
        queryFn: async rp => {
          try {
            await client.request(passwordReset(rp.token, rp.password));
            return {
              data: undefined,
            };
          } catch (error: any) {
            return { error };
          }
        },
        invalidatesTags: ['users'],
      }),
    }),
  });

export const {
  useGetUsersQuery,
  useInviteUserMutation,
  useDeleteUsersMutation,
  useConfirmInviteMutation,
  useRequestResetPasswordMutation,
  useResetPasswordMutation,
} = extentedApi;

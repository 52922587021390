import { Autocomplete, Box, TextField } from '@mui/material';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { useState } from 'react';
import { SyntheticEvent } from 'react-draft-wysiwyg';
import { useTranslation } from 'react-i18next';
import { useGetGeositesQuery } from 'services/endpoints/geositeEndpoints';
import { Geosite } from 'shared/model/types';

type Props = {
  value: Geosite | null;
  label?: string;
  onChange: (newValue: Geosite | null) => void;
  error?: boolean;
  helperText?: string;
  disabled?: boolean;
};

const GeositeAutocomplete = ({ value, onChange, error, helperText, disabled, label }: Props) => {
  const { t } = useTranslation();

  const [inputValue, setInputValue] = useState('');

  const { data, isLoading } = useGetGeositesQuery({
    sort: ['name'],
  });

  const onSelection = (e: SyntheticEvent, value: Geosite | null) => {
    onChange(value);
  };

  const options = data || [];

  const finalLabel = label ?? t('geosite.search');

  return (
    <Autocomplete
      autoComplete
      value={value ?? null}
      getOptionLabel={opt => opt.name ?? ''}
      getOptionKey={opt => opt.id}
      renderInput={params => (
        <TextField
          {...params}
          label={finalLabel}
          fullWidth
          disabled={disabled}
          error={error}
          helperText={helperText}
        />
      )}
      options={options}
      loading={isLoading}
      filterOptions={x => x}
      onChange={onSelection}
      includeInputInList
      noOptionsText={t('person.noResults')}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderOption={(props, option) => {
        const { key, ...optionProps } = props;

        const optionToSearch = option.name ?? '';

        const matches = match(optionToSearch, inputValue, { insideWords: true });
        const parts = parse(optionToSearch, matches);

        return (
          <li key={key} {...optionProps}>
            {parts.map((part, index) => (
              <Box
                key={index}
                component="span"
                sx={{
                  fontWeight: part.highlight ? 'bold' : 'regular',
                  whiteSpace: 'pre-wrap',
                }}
              >
                {part.text}
              </Box>
            ))}
          </li>
        );
      }}
    ></Autocomplete>
  );
};

export default GeositeAutocomplete;

import { zodResolver } from '@hookform/resolvers/zod';
import isObject from 'lodash/isObject';
import { useTranslation } from 'react-i18next';
import { TeamFormType } from 'shared/model/def/team.def';
import { Geosite, Person } from 'shared/model/types';
import { z, ZodType } from 'zod';

// Begin with https://transform.tools/typescript-to-zod

const useTeamResolver = () => {
  const { t } = useTranslation();

  const requiredMessage = t('form.errors.required');
  const emailMessage = t('form.errors.emailPattern');

  const schema = z.object({
    name: z.string().min(1, requiredMessage),
    geosite: z.custom<Geosite>(value => isObject(value), { message: requiredMessage }),
    adresse: z.string(),
    adresse_2: z.string(),
    cp: z.string(),
    city: z.string(),
    person: z.custom<Person>(value => isObject(value), { message: requiredMessage }),
    responsibleFunction: z.custom<Person>(value => isObject(value) || !value),
    phone: z.string(),
    fax: z.string(),
    email: z.string().email({ message: emailMessage }).or(z.null()).or(z.literal('')),
    web_site: z.string(),
  }) satisfies ZodType<TeamFormType>;

  return zodResolver(schema);
};
export default useTeamResolver;

export const useTeamCreationResolver = () => {
  const { t } = useTranslation();

  const requiredMessage = t('form.errors.required');

  const schema = z.object({
    name: z.string({ message: requiredMessage }),
  }) satisfies ZodType<TeamFormType>;

  return zodResolver(schema);
};

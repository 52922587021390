import SpatialReference from '@arcgis/core/geometry/SpatialReference';
import Graphic from '@arcgis/core/Graphic';
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer.js';
import ArcGisMap from '@arcgis/core/Map';
import SimpleMarkerSymbol from '@arcgis/core/symbols/SimpleMarkerSymbol';
import MapView from '@arcgis/core/views/MapView';
import clsx from 'clsx';
import { useEffect, useRef } from 'react';

import Point from '@arcgis/core/geometry/Point';
import './Map.css';

type Props = {
  point?: Point | null;
  className?: string;
  zoom?: number;
};

const Map = ({ point, className, zoom }: Props) => {
  const mapRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (mapRef?.current && point) {
      const g = new Graphic({
        geometry: point,
        symbol: new SimpleMarkerSymbol({
          color: [226, 119, 40],
          size: '24px',
          style: 'diamond',
        }),
      });

      const layer = new GraphicsLayer({
        graphics: [g],
      });

      const map = new ArcGisMap({
        basemap: 'osm',
        ground: 'world-elevation',
        layers: [layer],
      });

      const view = new MapView({
        map,
        container: mapRef.current,
        center: [0, 0],
        zoom,
        spatialReference: SpatialReference.WebMercator,
        constraints: {
          minZoom: 3,
          maxZoom: 14,
        },
        ui: {
          components: [],
        },
      });
      view.when(() => {
        view.goTo({
          target: point,
          zoom,
        });
      });
    }
  }, [mapRef, point]);

  if (!point) {
    return null;
  }

  return <div className={clsx('escape-map', className)} ref={mapRef}></div>;
};

export default Map;

import Point from '@arcgis/core/geometry/Point';
import { Box, Card, CardContent, Grid2 as Grid, Tab, Tabs } from '@mui/material';
import SearchResultPerson from 'modules/search/results/SearchResultPerson';
import DeletePersonsBtn from 'modules/sheet/person/DeletePersonsBtn';
import EditPersonBtn from 'modules/sheet/person/EditPersonBtn';
import { useGetPerson } from 'modules/sheet/sheetHook';
import { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useMatch, useNavigate } from 'react-router-dom';
import { useUpdatePersonMutation } from 'services/endpoints/PersonEndpoints';
import IconValue from 'shared/components/IconValue';
import Loader from 'shared/components/Loader';
import ShowAndEditGeom from 'shared/components/map/ShowAndEditGeom';
import { useSaveHistory } from 'shared/hooks/historyHook';
import ModelName from 'shared/model/def/ModelName';
import { PersonIcon } from 'shared/model/def/person.def';
import { closeAllDrawers } from 'shared/reducers/drawerSlice';
import { useAppDispatch } from 'shared/redux/redux-utils';

const tabs = [
  {
    label: 'sheetPerson.tabMain',
    path: 'main',
  },
  {
    label: 'sheetPerson.tabFollow',
    path: 'follow',
  },
  {
    label: 'sheetPerson.tabParticipations',
    path: 'participations',
  },
];

type Props = {
  children: React.ReactNode;
};

const PersonSheet = ({ children }: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const match = useMatch('/sheet/person/:id/:tab');

  const [updatePerson] = useUpdatePersonMutation();

  const { data, isLoading } = useGetPerson();

  useSaveHistory('person', data?.id, data ? data.firstName + ' ' + data.lastName : undefined);

  if (isLoading) {
    return <Loader />;
  }
  if (!data) {
    return null;
  }

  const tab = match?.params.tab;
  const tabValue = tabs.findIndex(item => item.path === tab) ?? 0;

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    const path = tabs[newValue].path;
    navigate(path);
  };

  const onDeleted = () => {
    navigate('/search');
  };

  const onSaveGeom = async (point: Point) => {
    if (data?.id) {
      updatePerson({
        id: data.id,
        item: {
          geom: {
            type: 'Point',
            coordinates: [point.longitude, point.latitude],
          },
        },
      }).unwrap();
      dispatch(closeAllDrawers());
    }
  };

  return (
    <Box px={1}>
      <Box pb={1}>
        <Card>
          <CardContent>
            <Grid container justifyContent="space-between">
              <Grid>
                <Box display="flex" alignItems="center">
                  <Box mr={1}>
                    <IconValue icon={<PersonIcon fontSize="large" />}>
                      <ModelName model={data} type="person" fontSize={24} />
                    </IconValue>
                  </Box>
                  <EditPersonBtn item={data} />
                  <DeletePersonsBtn ids={[data.id]} onDeleted={onDeleted} />
                </Box>
                <SearchResultPerson person={data} noDescription noScientificAttachments />
              </Grid>
              <Grid>
                <ShowAndEditGeom point={data.geom} onSave={onSaveGeom} />
              </Grid>
            </Grid>
            <Box></Box>
          </CardContent>
        </Card>
      </Box>
      <Box>
        <Card>
          <CardContent>
            <Box borderBottom={1} borderColor="divider">
              <Tabs value={tabValue} onChange={handleChange}>
                {tabs.map(tab => (
                  <Tab key={tab.path} label={t(tab.label)} />
                ))}
              </Tabs>
            </Box>
            <Box pt={1}>{children}</Box>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default PersonSheet;

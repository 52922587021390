import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DirectusErrorType } from 'services/api';
import { ISnackAlert } from 'shared/model/snackAlert.model';
import { ThunkAppDispatch } from 'store';
import { v4 as uuidv4 } from 'uuid';

const initialState = {
  alert: null as ISnackAlert | null,
};

export type AlertState = Readonly<typeof initialState>;

export const slice = createSlice({
  name: 'alertSnacks',
  initialState,
  reducers: {
    addAlert: (state, action: PayloadAction<ISnackAlert>) => {
      state.alert = action.payload;
    },
    closeAlert: state => {
      state.alert = null;
    },
    reset: state => {
      state.alert = null;
    },
  },
});

export const { reset, closeAlert, addAlert } = slice.actions;

export const addErrorAlert =
  (text: string, details?: string) => async (dispatch: ThunkAppDispatch) => {
    const errorAlert: ISnackAlert = {
      id: uuidv4(),
      text,
      severity: 'error',
      details,
      open: true,
    };
    dispatch(addAlert(errorAlert));
  };

export const addCatchErrorAlert = (e: any) => async (dispatch: ThunkAppDispatch) => {
  let text = e.data?.error?.message ?? e.message;

  if (Array.isArray(e.errors)) {
    const directusError = e as DirectusErrorType;
    text = directusError.errors[0].message;
  }

  const errorAlert: ISnackAlert = {
    id: uuidv4(),
    text,
    severity: 'error',
    open: true,
  };
  dispatch(addAlert(errorAlert));
};

export const addInfoAlert =
  (text: string, details?: string) => async (dispatch: ThunkAppDispatch) => {
    const errorAlert: ISnackAlert = {
      id: uuidv4(),
      text,
      severity: 'info',
      details,
      open: true,
    };
    dispatch(addAlert(errorAlert));
  };

export const addWarningAlert =
  (text: string, details?: string) => async (dispatch: ThunkAppDispatch) => {
    const errorAlert: ISnackAlert = {
      id: uuidv4(),
      text,
      details,
      severity: 'warning',
      open: true,
    };
    dispatch(addAlert(errorAlert));
  };

export const addSuccessAlert =
  (text: string, details?: string) => async (dispatch: ThunkAppDispatch) => {
    const errorAlert: ISnackAlert = {
      id: uuidv4(),
      text,
      details,
      severity: 'success',
      open: true,
    };
    dispatch(addAlert(errorAlert));
  };

export default slice.reducer;

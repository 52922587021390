import type { Middleware, MiddlewareAPI } from '@reduxjs/toolkit';
import { isRejectedWithValue } from '@reduxjs/toolkit';
import { addCatchErrorAlert } from 'shared/reducers/alertSlice';
import { logout } from 'shared/reducers/authSlice';

export const rtkQueryErrorLogger: Middleware = (api: MiddlewareAPI) => next => action => {
  // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
  if (isRejectedWithValue(action)) {
    console.warn('Action rejeté');
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    api.dispatch(addCatchErrorAlert(action.payload ?? action.error));
  }

  return next(action);
};

export const rtkQueryErrorUnauthorized: Middleware = () => next => action => {
  // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
  if (isRejectedWithValue(action) && (action as any).payload.status === 401) {
    console.log('Unauthorized');
  }

  return next(action);
};

export const rtkQueryErrorForbidden: Middleware = (api: MiddlewareAPI) => next => action => {
  // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
  if (isRejectedWithValue(action) && (action as any).payload.status === 403) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    api.dispatch(logout());
  }

  return next(action);
};

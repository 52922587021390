import Box from '@mui/material/Box/Box';
import Header from 'layout/header';

const ErrorPage = () => {
  return (
    <Box>
      <Header />
      <Box textAlign="center" padding={4}>
        Oups
      </Box>
    </Box>
  );
};
export default ErrorPage;

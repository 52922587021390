import Point from '@arcgis/core/geometry/Point';
import { Box, Card, CardContent } from '@mui/material';
import { useTranslation } from 'react-i18next';
import OpenDrawerButton from 'shared/components/buttons/OpenDrawerButton';
import EditGeomInMap from 'shared/components/map/EditGeomInMap';
import { Point as DatabasePoint } from 'shared/model/geometry.model';
import { closeAllDrawers } from 'shared/reducers/drawerSlice';
import { useAppDispatch } from 'shared/redux/redux-utils';
import Map from './Map';

const defaultPosition = import.meta.env.VITE_DEFAULT_POSITION;

const defaultLatitude = defaultPosition.split(',')[0];
const defaultLongitude = defaultPosition.split(',')[1];

type Props = {
  point?: DatabasePoint | null;
  className?: string;
  zoom?: number;
  onSave: (point: Point) => void;
};

const ShowAndEditGeom = ({ point, className, zoom, onSave }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const arcGisPoint = new Point({
    latitude: point?.coordinates[1] ?? defaultLatitude,
    longitude: point?.coordinates[0] ?? defaultLongitude,
  });

  const onCancel = () => {
    dispatch(closeAllDrawers());
  };

  const alignItems = point ? 'flex-start' : 'center';

  return (
    <Card>
      <CardContent>
        <Box display="flex" alignItems={alignItems}>
          {!point && <Box>{t('editGeom')}</Box>}
          <Map className={className} point={point ? arcGisPoint : undefined} zoom={zoom} />
          <Box display="flex" flexDirection="column">
            <OpenDrawerButton>
              <EditGeomInMap
                className={className}
                point={arcGisPoint}
                zoom={zoom}
                onCancel={onCancel}
                onSave={onSave}
              />
            </OpenDrawerButton>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default ShowAndEditGeom;

import { ListItem, ListItemText } from '@mui/material';
import { getTutelleItemName } from 'shared/components/tutelles/tutelleUtils';

import { Tutelle } from 'shared/model/types';

type Props = {
  item: Tutelle;
};

const TutelleItem = ({ item }: Props) => {
  const str = getTutelleItemName(item);

  return (
    <ListItem key={item.id}>
      <ListItemText primary={str} />
    </ListItem>
  );
};

export default TutelleItem;

import EditIcon from '@mui/icons-material/Edit';
import JobForm from 'modules/sheet/structure/jobs/JobForm';
import { useTranslation } from 'react-i18next';
import { useUpdateJobMutation } from 'services/endpoints/jobEndpoints';
import OpenDrawerButton from 'shared/components/buttons/OpenDrawerButton';
import { JobFormType } from 'shared/model/def/job.def';
import { Job } from 'shared/model/types';
import { closeAllDrawers } from 'shared/reducers/drawerSlice';
import { useAppDispatch } from 'shared/redux/redux-utils';

type Props = {
  job: Job;
};

const EditStructureJobBtn = ({ job }: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [update, { isLoading }] = useUpdateJobMutation();

  const onSubmit = async (newItem: JobFormType) => {
    await update({
      id: job.id + '',
      item: newItem,
    }).unwrap();

    dispatch(closeAllDrawers());
  };

  return (
    <OpenDrawerButton type="button" icon={<EditIcon />}>
      <JobForm job={job} disabledStructureSelection onSubmit={onSubmit} disabled={isLoading} />
    </OpenDrawerButton>
  );
};

export default EditStructureJobBtn;

import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from 'locales/en.json';
import fr from 'locales/fr.json';
import { initReactI18next } from 'react-i18next';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: 'fr',
    supportedLngs: ['fr', 'en'],
    load: 'languageOnly',
    react: {
      useSuspense: false,
    },
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        translation: en,
      },
      fr: {
        translation: fr,
      },
    },
  });
export default i18n;

import CategoryIcon from '@mui/icons-material/Category';
import EmailIcon from '@mui/icons-material/Email';
import EmergencyIcon from '@mui/icons-material/Emergency';
import FaxIcon from '@mui/icons-material/Fax';
import HomeIcon from '@mui/icons-material/Home';
import PhoneIcon from '@mui/icons-material/Phone';
import PlaceIcon from '@mui/icons-material/Place';
import WebIcon from '@mui/icons-material/Web';
import { Box, Grid2 as Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import DisplayHtml from 'shared/components/DisplayHtml';
import IconValue from 'shared/components/IconValue';
import { PersonIcon } from 'shared/model/def/person.def';
import { getStructureAdress } from 'shared/model/def/structure.def';
import { Geosite, Person, ResponsibleFunction, Structure, StructureType } from 'shared/model/types';
import { disablePropagation } from 'shared/utils/event-utils';
import { stringHtmlHasContent } from 'shared/utils/string-utils';

type Props = {
  structure: Structure;
  noDescription?: boolean;
};

const SearchResultStructure = ({ structure, noDescription = false }: Props) => {
  const person = structure.responsible as Person | undefined;
  const geosite = structure.geosite as Geosite | undefined;
  const type = structure.type as StructureType | undefined;

  const address = getStructureAdress(structure);

  const md = noDescription ? 12 : 6;
  const lg = noDescription ? 12 : 4;
  const xl = noDescription ? 12 : 4;

  const responsibleFunction = structure.responsibleFunction
    ? ` - ${(structure.responsibleFunction as ResponsibleFunction).name}`
    : '';

  return (
    <Grid container alignItems="flex-start" spacing={1} fontSize="0.9rem">
      <Grid
        size={{
          xs: 12,
          md: md,
          lg: lg,
          xl: xl,
        }}
      >
        {person && (
          <IconValue icon={<PersonIcon fontSize="small" />}>
            <Box onClick={disablePropagation}>
              <Link to={`/sheet/person/${person.id}/main`}>
                {person.firstName} {person.lastName}
              </Link>
              {responsibleFunction}
            </Box>
          </IconValue>
        )}
        {structure.email && (
          <IconValue icon={<EmailIcon fontSize="small" />}>
            <Box onClick={disablePropagation}>
              <a href={'mailto:' + structure.email}>{structure.email}</a>
            </Box>
          </IconValue>
        )}
        {type && (
          <IconValue icon={<CategoryIcon fontSize="small" />}>
            <Box>{type.name}</Box>
          </IconValue>
        )}
        {structure.shortName && (
          <IconValue icon={<EmergencyIcon fontSize="small" />}>{structure.shortName}</IconValue>
        )}
        {geosite && (
          <IconValue icon={<HomeIcon fontSize="small" />}>
            <Box>{geosite.name}</Box>
          </IconValue>
        )}
        {structure.webSite && (
          <IconValue icon={<WebIcon fontSize="small" />}>
            <Box onClick={disablePropagation}>
              <a href={structure.webSite} target="_blank">
                {structure.webSite}
              </a>
            </Box>
          </IconValue>
        )}

        {structure.phone && (
          <IconValue icon={<PhoneIcon fontSize="small" />}>
            <Box pt={0.2}>{structure.phone}</Box>
          </IconValue>
        )}

        {structure.fax && (
          <IconValue icon={<FaxIcon fontSize="small" />}>
            <Box pt={0.2}>{structure.fax}</Box>
          </IconValue>
        )}

        {address && (
          <IconValue icon={<PlaceIcon fontSize="small" />}>
            <Box pt={0.2}>{address}</Box>
          </IconValue>
        )}
      </Grid>
      {!noDescription && (
        <Grid
          size={{
            xs: 12,
            md: 6,
            lg: 8,
            xl: 8,
          }}
        >
          {structure.shortDescription && stringHtmlHasContent(structure.shortDescription) && (
            <DisplayHtml html={structure.shortDescription} />
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default SearchResultStructure;

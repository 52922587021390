import Box from '@mui/material/Box/Box';
import { createBrowserRouter, RouteObject } from 'react-router-dom';

import App from 'App';
import { headerMargin } from 'layout/layout.constant';

import AdminRoutes from 'modules/admin';
import HomeRoutes from 'modules/home';
import ProfilRoutes from 'modules/profile';
import SearchRoutes from 'modules/search';
import SheetRoutes from 'modules/sheet';
import LoginRoutes from 'modules/user';
import ErrorPage from 'shared/routes/errorPage';

const children = [
  ...HomeRoutes,
  ...LoginRoutes,
  ...AdminRoutes,
  ...ProfilRoutes,
  ...SearchRoutes,
  ...SheetRoutes,
];

const routes: RouteObject[] = [
  {
    path: '/',
    element: <App />,
    errorElement: (
      <Box sx={headerMargin}>
        <ErrorPage />
      </Box>
    ),
    children,
  },
];

const browserRouter = createBrowserRouter(routes, {
  basename: import.meta.env.BASE_URL,
});

export default browserRouter;

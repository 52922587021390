import { readMe, readRole } from '@directus/sdk';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import client from 'config/directus';
import api from 'services/api';
import { DirectusRoles, DirectusUsers } from 'shared/model/types';
import { ThunkAppDispatch } from 'store';

const initialState = {
  initializedOnLoad: false,
  currentUser: null as DirectusUsers | null,
  role: null as DirectusRoles | null,
};

export type AuthState = Readonly<typeof initialState>;

export const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setInitializedOnLoad: (state, action: PayloadAction<boolean>) => {
      state.initializedOnLoad = action.payload;
    },
    setCurrentUser(state, action: PayloadAction<DirectusUsers>) {
      state.currentUser = action.payload;
    },
    setRoles(state, action: PayloadAction<DirectusRoles>) {
      state.role = action.payload;
    },
    reset: state => {
      state.currentUser = null;
      state.role = null;
    },
  },
});

const { reset, setCurrentUser, setRoles, setInitializedOnLoad } = slice.actions;

const initUserSession = () => async (dispatch: ThunkAppDispatch) => {
  const me = (await client.request(readMe())) as DirectusUsers;
  dispatch(setCurrentUser(me));
  if (me.role) {
    const roles = await client.request(readRole(me.role as string));
    if (roles) {
      dispatch(setRoles(roles as DirectusRoles));
    }
  }
};

export const initSession = (onPageLoad: boolean) => async (dispatch: ThunkAppDispatch) => {
  try {
    const token = await client.refresh();
    if (token.access_token) {
      await dispatch(initUserSession());
    }
  } catch (e) {
    dispatch(reset());
  } finally {
    if (onPageLoad) {
      dispatch(setInitializedOnLoad(true));
    }
  }
};

export const login = (email: string, pwd: string) => async (dispatch: ThunkAppDispatch) => {
  try {
    const result = await client.login(email, pwd, { mode: 'cookie' });
    if (result.access_token) {
      dispatch(initUserSession());
    }
  } catch (e) {
    dispatch(reset());
    throw e;
  }
};

export const logout = () => async (dispatch: ThunkAppDispatch) => {
  await dispatch(api.util.resetApiState());
  await client.logout();
  dispatch(reset());
};

export default slice.reducer;

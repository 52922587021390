import { zodResolver } from '@hookform/resolvers/zod';
import { useTranslation } from 'react-i18next';
import { PersonFormType } from 'shared/model/def/person.def';
import { z, ZodType } from 'zod';

// Begin with https://transform.tools/typescript-to-zod

const usePersonResolver = () => {
  const { t } = useTranslation();

  const requiredMessage = t('form.errors.required');
  const emailMessage = t('form.errors.emailPattern');

  const schema = z.object({
    civilite: z.string(),
    firstName: z.string().min(1, requiredMessage),
    lastName: z.string().min(1, requiredMessage),
    email: z.string().email({ message: emailMessage }).or(z.null()).or(z.literal('')),
    website: z.string().optional().or(z.literal('')).or(z.null()),
    phone: z.string().optional().or(z.literal('')).or(z.null()),
    portable: z.string().optional().or(z.literal('')).or(z.null()),
    fax: z.string().optional().or(z.literal('')).or(z.null()),
  }) satisfies ZodType<PersonFormType>;

  return zodResolver(schema);
};
export default usePersonResolver;

export const usePersonCreationResolver = () => {
  const { t } = useTranslation();

  const requiredMessage = t('form.errors.required');

  const schema = z.object({
    civilite: z.string(),
    firstName: z.string().min(1, requiredMessage),
    lastName: z.string().min(1, requiredMessage),
  }) satisfies ZodType<PersonFormType>;

  return zodResolver(schema);
};

import { createItem, deleteItems, readItems, updateItem } from '@directus/sdk';

import directus, { MutationQuery, RequestQuery } from 'config/directus';
import api from 'services/api';
import { UserHistory } from 'shared/model/types';

export const extendedApi = api
  .enhanceEndpoints({
    addTagTypes: ['userHistory', 'userHistories'],
  })
  .injectEndpoints({
    overrideExisting: true,
    endpoints: builder => ({
      getUserHistories: builder.query<UserHistory[], RequestQuery<UserHistory>>({
        queryFn: async q => {
          try {
            const data = await directus.request<UserHistory[]>(readItems('userHistory', q));
            return { data };
          } catch (error: any) {
            return { error };
          }
        },
        providesTags: ['userHistories'],
      }),
      getUserHistory: builder.query<UserHistory[], RequestQuery<UserHistory>>({
        queryFn: async q => {
          try {
            const data = await directus.request<UserHistory[]>(readItems('userHistory', q));
            return { data };
          } catch (error: any) {
            return { error };
          }
        },
        providesTags: ['userHistory'],
      }),
      createUserHistory: builder.mutation<
        UserHistory,
        {
          item: Partial<UserHistory>;
          query?: MutationQuery<UserHistory>;
        }
      >({
        queryFn: async q => {
          try {
            const data = await directus.request<UserHistory>(
              createItem('userHistory', q.item, q.query),
            );
            return { data };
          } catch (error: any) {
            return { error };
          }
        },
        invalidatesTags: ['userHistory', 'userHistories'],
      }),
      updateUserHistory: builder.mutation<
        UserHistory,
        {
          id: string | number;
          item: Partial<UserHistory>;
          query?: MutationQuery<UserHistory>;
        }
      >({
        queryFn: async q => {
          try {
            const data = await directus.request<UserHistory>(
              updateItem('userHistory', q.id, q.item, q.query),
            );
            return { data };
          } catch (error: any) {
            return { error };
          }
        },
        invalidatesTags: ['userHistory', 'userHistories'],
      }),
      deleteUserHistory: builder.mutation<void, UserHistory[]>({
        queryFn: async userHistory => {
          try {
            const ids = userHistory.map(item => item.id);
            await directus.request(deleteItems('userHistory', ids));
            return {
              data: undefined,
            };
          } catch (error: any) {
            return { error };
          }
        },
        invalidatesTags: ['userHistory', 'userHistories'],
      }),
    }),
  });

export const {
  useGetUserHistoriesQuery,
  useGetUserHistoryQuery,
  useCreateUserHistoryMutation,
  useUpdateUserHistoryMutation,
  useDeleteUserHistoryMutation,
} = extendedApi;

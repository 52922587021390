import Box from '@mui/material/Box';
import Header from 'layout/header';

import { jwtDecode } from 'jwt-decode';
import AppHelmet from 'modules/AppHelmet';
import { Token } from 'modules/admin/users/InviteUser.model';
import { Outlet, matchPath, useLocation } from 'react-router';
import { Navigate, useMatch, useSearchParams } from 'react-router-dom';
import AlertSnacks from 'shared/components/AlertSnacks';
import Loader from 'shared/components/Loader';
import Ribbon from 'shared/components/ribbon/Ribbon';
import { useAppSelector } from 'shared/redux/redux-utils';
import PrivateRoute from 'shared/routes/privateRoute';
import './App.scss';

const publicPaths = ['/', 'login', '/confirm-invite', 'reset-password', 'request-reset-password'];

const App = () => {
  const location = useLocation();
  const isInitialized = useAppSelector(state => state.auth.initializedOnLoad);

  const isHomePage = useMatch('/');
  const [searchParams] = useSearchParams();

  const token = searchParams.get('token');

  const isPublic = publicPaths.some(publicPath => matchPath(publicPath, location.pathname));

  if (!isInitialized) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: 'calc(100vh - 40px)',
        }}
      >
        <Loader />
      </Box>
    );
  }

  if (token && isHomePage) {
    const info = jwtDecode(token) as Token;
    console.log(info);
    if (info.scope === 'invite') {
      return (
        <Navigate
          replace
          to={{
            pathname: '/confirm-invite',
            search: 'token=' + token,
          }}
        />
      );
    } else if (info.scope === 'password-reset') {
      return (
        <Navigate
          replace
          to={{
            pathname: '/reset-password',
            search: 'token=' + token,
          }}
        />
      );
    }
  }

  const mt = isHomePage ? '32px' : '48px';

  return (
    <>
      <AppHelmet />
      <Box mt={mt}>
        <Ribbon />
        <Header />
        <Box pt={1} minHeight={`50vh`}>
          {isPublic ? (
            <Outlet />
          ) : (
            <PrivateRoute>
              <Outlet />
            </PrivateRoute>
          )}
          <AlertSnacks />
        </Box>
      </Box>
    </>
  );
};

export default App;

import StructureForm from 'modules/sheet/structure/StructureForm';
import { useUpdateStructureMutation } from 'services/endpoints/StructureEndpoints';
import OpenDrawerButton from 'shared/components/buttons/OpenDrawerButton';
import { StructureFormType } from 'shared/model/def/structure.def';
import { Structure } from 'shared/model/types';
import { closeAllDrawers } from 'shared/reducers/drawerSlice';
import { useAppDispatch } from 'shared/redux/redux-utils';

type Props = {
  item: Structure;
};

const EditStructureBtn = ({ item }: Props) => {
  const dispatch = useAppDispatch();

  const [updateItem, { isLoading }] = useUpdateStructureMutation();

  const onSubmit = async (newItem: StructureFormType) => {
    await updateItem({
      id: item.id,
      item: newItem,
    }).unwrap();

    dispatch(closeAllDrawers());
  };
  return (
    <OpenDrawerButton>
      <StructureForm structure={item} onSubmit={onSubmit} disabled={isLoading} />
    </OpenDrawerButton>
  );
};

export default EditStructureBtn;

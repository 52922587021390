import EditIcon from '@mui/icons-material/Edit';
import { Box } from '@mui/material';
import isObject from 'lodash/isObject';
import { TeamMemberFormType } from 'modules/sheet/team/teamMember/teamMember.def';
import TeamMemberForm from 'modules/sheet/team/teamMember/TeamMemberForm';
import { useTranslation } from 'react-i18next';
import { useUpdateTeamMemberMutation } from 'services/endpoints/TeamMemberEndpoints';
import OpenDrawerButton from 'shared/components/buttons/OpenDrawerButton';
import { TeamMember } from 'shared/model/types';
import { closeAllDrawers } from 'shared/reducers/drawerSlice';
import { useAppDispatch } from 'shared/redux/redux-utils';

type Props = {
  teamMember: TeamMember;
};

const EditTeamMember = ({ teamMember }: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [update, { isLoading }] = useUpdateTeamMemberMutation();

  const onSubmit = async (newTeamMember: TeamMemberFormType) => {
    if (
      isObject(newTeamMember.person) &&
      isObject(newTeamMember.metier) &&
      isObject(newTeamMember.statut)
    ) {
      await update({
        id: teamMember.id,
        item: {
          label: newTeamMember.label,
          person: newTeamMember.person.id,
          metier: newTeamMember.metier.id,
          statut: newTeamMember.statut.id,
          startDate: newTeamMember.startDate,
          endDate: newTeamMember.endDate,
        },
      }).unwrap();
      dispatch(closeAllDrawers());
    }
  };

  const disabled = isLoading;

  return (
    <OpenDrawerButton type="button" icon={<EditIcon />}>
      <Box p={2}>
        <TeamMemberForm teamMember={teamMember} onSubmit={onSubmit} disabled={disabled} />
      </Box>
    </OpenDrawerButton>
  );
};

export default EditTeamMember;

import Box from '@mui/material/Box/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper/Paper';
import TextField from '@mui/material/TextField';

import { Credentials } from 'modules/user/login/login.model';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useMatch, useNavigate } from 'react-router-dom';

import FormDebugError from 'shared/components/form/FormDebugError';
import PasswordTextField from 'shared/components/form/PasswordTextField';
import { useDirectusAppLogoUrl } from 'shared/hooks/directus-hook';
import { addCatchErrorAlert } from 'shared/reducers/alertSlice';
import { login } from 'shared/reducers/authSlice';

import { useAppDispatch } from 'shared/redux/redux-utils';
import useLoginResolver from 'shared/resolvers/useLoginResolver';

const appName = import.meta.env.VITE_APP_TITLE;

const LoginPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const logoUrl = useDirectusAppLogoUrl();

  const { t } = useTranslation();

  const isLoginPage = useMatch('/login');

  const resolver = useLoginResolver();

  const [loading, setLoading] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<Credentials>({
    defaultValues: {
      email: undefined,
      password: undefined,
    },
    resolver,
  });

  const onSubmit = async (data: Credentials) => {
    try {
      setLoading(true);
      await dispatch(login(data.email, data.password));
      if (isLoginPage) {
        navigate('/');
      } else {
        navigate(0);
      }
    } catch (e) {
      dispatch(addCatchErrorAlert(e));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="calc(100vh - 200px)"
    >
      <Box>
        <Paper elevation={4} sx={{ padding: 2 }}>
          <Box p={2}>
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
              <Box mb={2} display="flex" alignItems="center">
                <Box>
                  <img alt="logo" src={`${logoUrl}?key=logo-big`} />
                </Box>
                <Box ml={1} fontWeight="bold" fontSize="large">
                  {appName}
                </Box>
              </Box>
              <Box width={350}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Box mt={2}>
                    <Controller
                      name="email"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          label={t('email')}
                          {...field}
                          disabled={loading}
                          error={!!errors.email}
                          helperText={errors.email?.message}
                        />
                      )}
                    />
                  </Box>
                  <Box mt={2}>
                    <Controller
                      name="password"
                      control={control}
                      render={({ field }) => {
                        return (
                          <PasswordTextField
                            label={t('password')}
                            type="password"
                            {...field}
                            disabled={loading}
                            error={!!errors.password}
                            helperText={errors.password?.message}
                          />
                        );
                      }}
                    />
                  </Box>
                  <Box mt={2}>
                    <Button type="submit" fullWidth disabled={loading}>
                      {t('connexion')}
                    </Button>
                  </Box>
                </form>
              </Box>
              <Box mt={4} textAlign="center">
                <Link to="/request-reset-password">{t('passwordForgotten')}</Link>
              </Box>
              <Box mt={4} textAlign="center">
                <Box mb={1}>{t('register')}</Box>
                <Box>
                  <Link to="/register">{t('registerBtn')}</Link>
                </Box>
              </Box>
            </Box>
          </Box>
          <FormDebugError errors={errors} />
        </Paper>
      </Box>
    </Box>
  );
};

export default LoginPage;

import { createItem, deleteItems, readItem, readItems, updateItem } from '@directus/sdk';

import directus, { MutationQuery, RequestQuery } from 'config/directus';
import api from 'services/api';
import { Organization } from 'shared/model/types';

export const extendedApi = api
  .enhanceEndpoints({
    addTagTypes: ['Organization', 'Organizations'],
  })
  .injectEndpoints({
    overrideExisting: true,
    endpoints: builder => ({
      getOrganizations: builder.query<Organization[], RequestQuery<Organization>>({
        queryFn: async q => {
          const data = await directus.request<Organization[]>(readItems('organization', q));
          return { data };
        },
        providesTags: ['Organizations'],
      }),
      getOrganization: builder.query<Organization, { id: string; q?: RequestQuery<Organization> }>({
        queryFn: async q => {
          const data = await directus.request<Organization>(readItem('organization', q.id, q.q));
          return { data };
        },
        providesTags: ['Organization'],
      }),
      createOrganization: builder.mutation<
        Organization,
        {
          file: File;
          item: Partial<Organization>;
          query?: MutationQuery<Organization>;
        }
      >({
        queryFn: async q => {
          const data = await directus.request<Organization>(
            createItem('organization', q.item, q.query),
          );
          return { data };
        },
        invalidatesTags: ['Organization', 'Organizations'],
      }),
      updateOrganization: builder.mutation<
        Organization,
        {
          id: string;
          item: Partial<Organization>;
          query?: MutationQuery<Organization>;
        }
      >({
        queryFn: async q => {
          const data = await directus.request<Organization>(
            updateItem('organization', q.id, q.item, q.query),
          );
          return { data };
        },
        invalidatesTags: ['Organization', 'Organizations'],
      }),
      deleteOrganization: builder.mutation<void, Organization[]>({
        queryFn: async Organization => {
          const ids = Organization.map(item => item.id);
          await directus.request(deleteItems('organization', ids));

          return {
            data: undefined,
          };
        },
        invalidatesTags: ['Organization', 'Organizations'],
      }),
    }),
  });

export const {
  useGetOrganizationsQuery,
  useGetOrganizationQuery,
  useCreateOrganizationMutation,
  useUpdateOrganizationMutation,
  useDeleteOrganizationMutation,
} = extendedApi;

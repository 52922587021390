import Point from '@arcgis/core/geometry/Point';
import { Box, Card, CardContent, Grid2 as Grid, Tab, Tabs } from '@mui/material';
import SearchResultTeam from 'modules/search/results/SearchResultTeam';
import { useGetTeam } from 'modules/sheet/sheetHook';
import DeleteTeamsBtn from 'modules/sheet/team/DeleteTeamsBtn';
import EditTeamBtn from 'modules/sheet/team/EditTeamBtn';
import { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useMatch, useNavigate } from 'react-router-dom';
import { useUpdateTeamMutation } from 'services/endpoints/TeamEndpoints';
import IconValue from 'shared/components/IconValue';
import Loader from 'shared/components/Loader';
import ShowAndEditGeom from 'shared/components/map/ShowAndEditGeom';
import { useSaveHistory } from 'shared/hooks/historyHook';
import ModelName from 'shared/model/def/ModelName';
import { TeamIcon } from 'shared/model/def/team.def';
import { closeAllDrawers } from 'shared/reducers/drawerSlice';
import { useAppDispatch } from 'shared/redux/redux-utils';

const tabs = [
  {
    label: 'sheetTeam.tabMain',
    path: 'main',
  },
  {
    label: 'sheetTeam.tabPresentation',
    path: 'presentation',
  },
  {
    label: 'sheetTeam.tabMembers',
    path: 'members',
  },
];

type Props = {
  children: React.ReactNode;
};

const TeamSheet = ({ children }: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [updateTeam] = useUpdateTeamMutation();

  const { data, isLoading } = useGetTeam();

  useSaveHistory('team', data?.id, data?.name);

  const match = useMatch('/sheet/team/:id/:tab');

  if (isLoading) {
    return <Loader />;
  }

  if (!data) {
    return null;
  }

  const tab = match?.params.tab;
  const tabValue = tabs.findIndex(item => item.path === tab) ?? 0;

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    const path = tabs[newValue].path;
    navigate(path);
  };

  const onDeleted = () => {
    navigate('/search');
  };

  const onSaveGeom = async (point: Point) => {
    updateTeam({
      id: data.id,
      item: {
        geom: {
          type: 'Point',
          coordinates: [point.longitude, point.latitude],
        },
      },
    }).unwrap();
    dispatch(closeAllDrawers());
  };

  return (
    <Box px={1}>
      <Box pb={1}>
        <Card>
          <CardContent>
            <Grid container justifyContent="space-between">
              <Grid>
                <Box display="flex" alignItems="center">
                  <Box mr={1}>
                    <IconValue icon={<TeamIcon fontSize="large" />}>
                      <ModelName model={data} type="team" fontSize={24} />
                    </IconValue>
                  </Box>
                  <EditTeamBtn team={data} />
                  <DeleteTeamsBtn ids={[data.id]} onDeleted={onDeleted} />
                </Box>
                <SearchResultTeam team={data} noDescription />
              </Grid>
              <Grid>
                <ShowAndEditGeom point={data.geom} onSave={onSaveGeom} />
              </Grid>
            </Grid>
            <Box></Box>
          </CardContent>
        </Card>
      </Box>
      <Box>
        <Card>
          <CardContent>
            <Box borderBottom={1} borderColor="divider">
              <Tabs value={tabValue} onChange={handleChange}>
                {tabs.map(tab => (
                  <Tab key={tab.path} label={t(tab.label)} />
                ))}
              </Tabs>
            </Box>
            <Box pt={1}>{children}</Box>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default TeamSheet;

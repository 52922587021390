import Box from '@mui/material/Box';
import AdminHome from 'modules/home/AdminHome';
import SearchHomeBar from 'modules/home/SearchHomeBar';
import { useIsAdmin, useIsAuthenticated } from 'shared/hooks/auth-hook';
import {
  useDirectusAppPublicBackgroundUrl,
  useDirectusAppPublicForeGroundUrl,
  useDirectusProjectNote,
} from 'shared/hooks/directus-hook';

const HomePage = () => {
  const foregroundUrl = useDirectusAppPublicForeGroundUrl();
  const backgroundUrl = useDirectusAppPublicBackgroundUrl();
  const publicNote = useDirectusProjectNote();

  const isAdmin = useIsAdmin();
  const isAuthenticated = useIsAuthenticated();

  return (
    <>
      <Box position="relative" height="240px">
        <Box display="flex" alignItems="center" mt={1} zIndex={2} position="absolute">
          {foregroundUrl && (
            <Box
              component="img"
              src={`${foregroundUrl}`}
              alt=""
              sx={{
                marginRight: 2,
                cursor: 'pointer',
              }}
            />
          )}
          <Box>
            {publicNote && <Box dangerouslySetInnerHTML={{ __html: publicNote }}></Box>}
            {isAdmin && (
              <Box>
                <SearchHomeBar />
              </Box>
            )}
          </Box>
        </Box>
        {backgroundUrl && (
          <Box
            component="img"
            src={`${backgroundUrl}`}
            alt=""
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
              cursor: 'pointer',
              zIndex: 1,
            }}
          />
        )}
      </Box>
      <Box>{isAdmin && <AdminHome />}</Box>
    </>
  );
};

export default HomePage;

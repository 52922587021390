import { readItems } from '@directus/sdk';
import directus, { RequestQuery } from 'config/directus';
import api from 'services/api';
import { Statut } from 'shared/model/types';

const statutApi = api
  .enhanceEndpoints({
    addTagTypes: ['statuts'],
  })
  .injectEndpoints({
    overrideExisting: true,
    endpoints: builder => ({
      getStatuts: builder.query<Statut[], RequestQuery<Statut>>({
        queryFn: async q => {
          try {
            const data = await directus.request<Statut[]>(readItems('Statut', q));
            return { data };
          } catch (error: any) {
            return { error };
          }
        },
        providesTags: ['statuts'],
      }),
    }),
  });

export const { useGetStatutsQuery } = statutApi;

import { createItem, deleteItems, readItem, readItems, updateItem } from '@directus/sdk';

import directus, { MutationQuery, RequestQuery } from 'config/directus';
import api from 'services/api';

import { Person } from 'shared/model/types';

export const extendedApi = api
  .enhanceEndpoints({
    addTagTypes: ['person', 'persons'],
  })
  .injectEndpoints({
    overrideExisting: true,
    endpoints: builder => ({
      getPersons: builder.query<Person[], RequestQuery<Person>>({
        queryFn: async q => {
          try {
            const data = await directus.request<Person[]>(readItems('Person', q));
            return { data };
          } catch (error: any) {
            return { error };
          }
        },
        providesTags: ['persons'],
      }),
      getPerson: builder.query<Person, { id: string; q?: RequestQuery<Person> }>({
        queryFn: async q => {
          try {
            const data = await directus.request<Person>(readItem('Person', q.id, q.q));
            return { data };
          } catch (error: any) {
            return { error };
          }
        },
        providesTags: ['person'],
      }),
      createPerson: builder.mutation<
        Person,
        {
          item: Partial<Person>;
          query?: MutationQuery<Person>;
        }
      >({
        queryFn: async q => {
          try {
            const data = await directus.request<Person>(createItem('Person', q.item, q.query));
            return { data };
          } catch (error: any) {
            return { error };
          }
        },
        invalidatesTags: ['person', 'persons'],
      }),
      updatePerson: builder.mutation<
        Person,
        {
          id: string | number;
          item: Partial<Person>;
          query?: MutationQuery<Person>;
        }
      >({
        queryFn: async q => {
          try {
            const data = await directus.request<Person>(
              updateItem('Person', q.id, q.item, q.query),
            );
            return { data };
          } catch (error: any) {
            return { error };
          }
        },
        invalidatesTags: ['person', 'persons'],
      }),
      deletePerson: builder.mutation<void, string[]>({
        queryFn: async ids => {
          try {
            await directus.request(deleteItems('Person', ids));
            return {
              data: undefined,
            };
          } catch (error: any) {
            return { error };
          }
        },
        invalidatesTags: ['person', 'persons'],
      }),
    }),
  });

export const {
  useGetPersonsQuery,
  useGetPersonQuery,
  useCreatePersonMutation,
  useUpdatePersonMutation,
  useDeletePersonMutation,
} = extendedApi;

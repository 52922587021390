import Point from '@arcgis/core/geometry/Point';
import SpatialReference from '@arcgis/core/geometry/SpatialReference';
import Graphic from '@arcgis/core/Graphic';
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer';
import ArcGisMap from '@arcgis/core/Map';
import SimpleMarkerSymbol from '@arcgis/core/symbols/SimpleMarkerSymbol';
import MapView from '@arcgis/core/views/MapView';
import SketchViewModel from '@arcgis/core/widgets/Sketch/SketchViewModel';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import { Box, Button, Card, CardActions, CardContent, CardHeader } from '@mui/material';
import clsx from 'clsx';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

const defaultPosition = import.meta.env.VITE_DEFAULT_POSITION;

const defaultLatitude = defaultPosition.split(',')[0];
const defaultLongitude = defaultPosition.split(',')[1];

const symbol = new SimpleMarkerSymbol({
  color: [60, 100, 170],
  size: '24px',
  style: 'diamond',
  outline: {
    color: [255, 255, 255],
    width: 1,
  },
});

type Props = {
  point?: Point | null;
  className?: string;
  zoom?: number;
  onSave: (point: Point) => void;
  onCancel: () => void;
};

const EditGeomInMap = ({ point, className, zoom = 12, onSave, onCancel }: Props) => {
  const { t } = useTranslation();
  const mapRef = useRef<HTMLDivElement>(null);
  const vmLayer = useRef<GraphicsLayer>();

  useEffect(() => {
    if (mapRef?.current) {
      const layer = new GraphicsLayer();
      vmLayer.current = layer;

      const map = new ArcGisMap({
        basemap: 'osm',
        ground: 'world-elevation',
        layers: [layer],
      });

      const view = new MapView({
        map,
        container: mapRef.current,
        center: [defaultLongitude, defaultLatitude],
        zoom,
        spatialReference: SpatialReference.WebMercator,
        constraints: {
          minZoom: 3,
        },
        ui: {
          components: [],
        },
      });

      const vm = new SketchViewModel({
        view,
        layer,
        pointSymbol: symbol,
        defaultCreateOptions: {
          hasZ: false,
        },
        defaultUpdateOptions: {
          enableZ: false,
        },
      });

      if (point) {
        view.when(() => {
          const g = new Graphic({
            geometry: point,
            symbol,
          });
          layer.add(g);

          vm.update(g);

          view.goTo({
            target: point,
            zoom,
          });
        });
      } else {
        vm.create('point');
      }
    }
  }, [mapRef]);

  const onSaveClick = () => {
    const graphics = vmLayer.current?.graphics;
    if (graphics && graphics.length > 0) {
      const g = graphics.getItemAt(0);
      onSave(g.geometry as Point);
    }
  };

  return (
    <Box p={1}>
      <Card>
        <CardHeader
          title={t('editGeom')}
          subheader={t('editGeomDescription')}
          sx={{
            paddingBottom: 0,
          }}
        />

        <CardContent>
          <div className={clsx('escape-map', className)} ref={mapRef}></div>
        </CardContent>
        <CardActions
          sx={{
            justifyContent: 'center',
          }}
        >
          <Button onClick={onCancel} startIcon={<CancelIcon />} variant="outlined">
            {t('cancel')}
          </Button>
          <Button onClick={onSaveClick} color="primary" startIcon={<SaveIcon />}>
            {t('save')}
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
};

export default EditGeomInMap;

import { RouteObject } from 'react-router-dom';
import PrivateRoute from 'shared/routes/privateRoute';
import { Role } from 'shared/types/role';
import SearchPage from './SearchPage';

const routes: RouteObject[] = [
  {
    path: '/search',
    index: true,
    element: (
      <PrivateRoute roles={[Role.Admin, Role.User]}>
        <SearchPage />
      </PrivateRoute>
    ),
  },
];

export default routes;

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import { useEffect, useState } from 'react';

type Props = {
  fullScreen?: boolean;
  delay?: number;
  linear?: boolean;
  size?: string | number;
};

const Loader = ({ fullScreen = false, delay = 500, linear = false, size }: Props) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setShow(true), delay);
    return () => clearTimeout(timer);
  }, [delay]);

  if (!show) {
    return null;
  }

  const progress = linear ? <LinearProgress /> : <CircularProgress size={size} />;

  if (fullScreen) {
    return (
      <Box
        sx={{
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {progress}
      </Box>
    );
  }

  return progress;
};

export default Loader;

import { Box, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import useJobResolver from 'modules/sheet/structure/jobs/useJobResolver';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import MetierAutocomplete from 'shared/components/autocomplete/MetierAutocomplete';
import PersonAutocomplete from 'shared/components/autocomplete/PersonAutocomplete';
import StatutAutocomplete from 'shared/components/autocomplete/StatutAutocomplete';
import StructureAutocomplete from 'shared/components/autocomplete/StructureAutocomplete';
import FormButtons from 'shared/components/form/FormButtons';
import FormTemplate from 'shared/components/form/FormTemplate';
import { JobFormType, JobIcon } from 'shared/model/def/job.def';
import { Job, Metier, Person, Statut, Structure } from 'shared/model/types';
import { closeAllDrawers } from 'shared/reducers/drawerSlice';
import { useAppDispatch } from 'shared/redux/redux-utils';

type Props = {
  job: Partial<Job>;
  disabledStructureSelection?: boolean;
  disabled?: boolean;
  onSubmit: (job: JobFormType) => void;
};

const JobForm = ({
  job,
  disabled = false,
  disabledStructureSelection = false,
  onSubmit,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const resolver = useJobResolver();

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm<JobFormType>({
    defaultValues: {
      ...job,
    },
    resolver,
  });

  const onCancel = () => {
    dispatch(closeAllDrawers());
  };

  const metierSelected = watch('metier') as Metier | undefined;

  const titleKey = job.id ? 'job.edit' : 'job.add';

  return (
    <Box>
      <FormTemplate fullWidth titlei18n={titleKey} icon={<JobIcon />} errors={errors}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box mt={2}>
            <Controller
              name="structure"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <StructureAutocomplete
                    label={t('structure.structure')}
                    value={value as Structure | null}
                    onChange={onChange}
                    error={!!errors.structure}
                    helperText={errors.structure?.message}
                    disabled={disabledStructureSelection}
                  />
                );
              }}
            />
          </Box>
          <Box mt={2}>
            <Controller
              name="person"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <PersonAutocomplete
                    label={t('person.person')}
                    value={value as Person | null}
                    onChange={onChange}
                    error={!!errors.person}
                    helperText={errors.person?.message}
                  />
                );
              }}
            />
          </Box>
          <Box mt={2}>
            <Controller
              name="label"
              control={control}
              render={({ field }) => (
                <TextField
                  label={t('label')}
                  {...field}
                  error={!!errors.label}
                  helperText={errors.label?.message}
                />
              )}
            />
          </Box>
          <Box mt={2}>
            <Controller
              name="metier"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <MetierAutocomplete
                    value={value as Metier | null}
                    onChange={onChange}
                    error={!!errors.metier}
                    helperText={errors.metier?.message}
                  />
                );
              }}
            />
          </Box>
          <Box mt={2}>
            <Controller
              name="statut"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <StatutAutocomplete
                    value={value as Statut | null}
                    onChange={onChange}
                    error={!!errors.statut}
                    helperText={errors.statut?.message}
                    metier={metierSelected}
                  />
                );
              }}
            />
          </Box>
          <Box mt={2}>
            <Controller
              control={control}
              name="startDate"
              render={({ field }) => {
                return (
                  <DatePicker
                    label={t('startDate')}
                    value={field.value ? dayjs(field.value) : null}
                    inputRef={field.ref}
                    timezone="UTC"
                    onChange={date => {
                      field.onChange(date);
                    }}
                    format="DD/MM/YYYY"
                    slotProps={{
                      textField: {
                        required: !!errors.startDate,
                        helperText: errors.startDate?.message,
                      },
                    }}
                  />
                );
              }}
            />
          </Box>
          <Box mt={2}>
            <Controller
              control={control}
              name="endDate"
              render={({ field }) => {
                return (
                  <DatePicker
                    label={t('endDate')}
                    value={field.value ? dayjs(field.value) : null}
                    inputRef={field.ref}
                    onChange={date => {
                      field.onChange(date);
                    }}
                    timezone="UTC"
                    format="DD/MM/YYYY"
                    slotProps={{
                      textField: {
                        required: !!errors.endDate,
                        helperText: errors.endDate?.message,
                      },
                    }}
                  />
                );
              }}
            />
          </Box>
          <FormButtons onCancel={onCancel} disabled={disabled} />
        </form>
      </FormTemplate>
    </Box>
  );
};

export default JobForm;

import { QueryDslQueryContainer } from '@elastic/elasticsearch/lib/api/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EscapeSearch } from 'shared/types/EscapeSearch.model';
import { ThunkAppDispatch } from 'store';

const indexFilter = '';

const from = 0;
const size = 20;

const initialState = {
  indexFilter,
  query: null as QueryDslQueryContainer | null,
  from,
  size,
};

export type AlertState = Readonly<typeof initialState>;

export const slice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setQuery: (state, action: PayloadAction<QueryDslQueryContainer | null>) => {
      state.query = action.payload;
    },
    setIndexFilter: (state, action: PayloadAction<string>) => {
      state.indexFilter = action.payload;
    },
    setFrom: (state, action: PayloadAction<number>) => {
      state.from = action.payload;
    },
    setSize: (state, action: PayloadAction<number>) => {
      state.size = action.payload;
    },
    reset: state => {
      state.indexFilter = '';
      state.query = null;
      state.from = from;
      state.size = size;
    },
  },
});

export const { reset, setQuery, setIndexFilter, setFrom } = slice.actions;

export const initWithLastSearch =
  (lastSearch?: EscapeSearch | null) => async (dispatch: ThunkAppDispatch) => {
    if (lastSearch) {
      const indexFilter = lastSearch?.index ?? '';
      const query = lastSearch?.search?.query ?? null;

      dispatch(setIndexFilter(indexFilter));
      dispatch(setQuery(query));
    }
  };

export default slice.reducer;

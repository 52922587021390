import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ModelIcon from 'shared/model/def/ModelIcon';
import { UserHistory } from 'shared/model/types';

type Props = {
  item: UserHistory;
  afterClick: () => void;
};

const UserHistoryItem = ({ item, afterClick }: Props) => {
  const navigate = useNavigate();

  const { type, name, objectId } = item;

  const onClick = () => {
    navigate(`/sheet/${type}/${objectId}/main`);
    afterClick();
  };

  return (
    <MenuItem onClick={onClick}>
      {type && (
        <ListItemIcon>
          <ModelIcon model={type} />
        </ListItemIcon>
      )}
      <ListItemText
        sx={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          maxWidth: '400px',
          whiteSpace: 'nowrap',
        }}
        title={name ?? ''}
      >
        {name}
      </ListItemText>
    </MenuItem>
  );
};

export default UserHistoryItem;

import ConfirmTeamSelection from 'modules/sheet/structure/teams/ConfirmTeamSelection';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import OpenDrawerButton from 'shared/components/buttons/OpenDrawerButton';
import TeamsSelection from 'shared/components/team/TeamsSelection';
import { Team } from 'shared/model/types';
import { closeAllDrawers } from 'shared/reducers/drawerSlice';
import { useAppDispatch } from 'shared/redux/redux-utils';

type Props = {
  structureId: number;
  selected: Team[];
};

const StructureSelectTeamsBtn = ({ selected, structureId }: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [toAdd, setToAdd] = useState<Team[]>([]);
  const [toRemove, setToRemove] = useState<Team[]>([]);

  const onChange = async (selectedTeams: Team[]) => {
    const teamsToRemove = selected.filter(s => !selectedTeams.find(t => t.id === s.id));
    setToRemove(teamsToRemove);

    const teamsToAdd = selectedTeams.filter(t => !selected.find(s => s.id === t.id));
    setToAdd(teamsToAdd);

    dispatch(closeAllDrawers());
  };

  const openConfirm = toAdd.length > 0 || toRemove.length > 0;

  const onCloseConfirm = () => {
    setToAdd([]);
    setToRemove([]);
  };

  return (
    <>
      <OpenDrawerButton text={t('select')} type="txtButton">
        <TeamsSelection
          selected={selected.map(item => item.id)}
          onChange={onChange}
          tableSx={{
            height: 'calc(100vh - 130px)',
          }}
        />
      </OpenDrawerButton>
      {openConfirm && (
        <ConfirmTeamSelection
          structureId={structureId}
          teamsToAdd={toAdd}
          teamsToRemove={toRemove}
          onClose={onCloseConfirm}
        />
      )}
    </>
  );
};

export default StructureSelectTeamsBtn;

import { Autocomplete, Box, Button, TextField } from '@mui/material';
import { SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetOrganizationsQuery } from 'services/endpoints/OrganizationEndpoints';
import { Organization } from 'shared/model/types';

type Props = {
  selectedOrganization: Organization | null;
  selectedCode: string;
  onSubmit: (organization: Organization, code: string) => void;
};

const TutelleForm = ({ selectedOrganization, selectedCode, onSubmit }: Props) => {
  const { t } = useTranslation();
  const { data, isLoading } = useGetOrganizationsQuery({
    limit: 200,
    sort: 'name',
  });

  const organizations = data ?? [];

  const [organization, setOrganization] = useState<Organization | null>(null);
  const [code, setCode] = useState<string>('');

  useEffect(() => {
    setCode(selectedCode);
    setOrganization(selectedOrganization);
  }, [selectedOrganization, selectedCode]);

  const onClick = () => {
    if (organization) {
      onSubmit(organization, code);
    }
  };

  const onOrganizationChange = (e: SyntheticEvent, newValue: Organization | null) => {
    setOrganization(newValue);
  };

  const onCodeChange = (e: SyntheticEvent) => {
    setCode((e.target as HTMLInputElement).value);
  };

  const btnLabel =
    selectedOrganization && organization?.id === selectedOrganization?.id ? t('edit') : t('add');

  const disabled = !organization;

  return (
    <Box>
      <Box mb={1}>
        <Autocomplete
          disablePortal
          loading={isLoading}
          getOptionLabel={option => option.name ?? ''}
          options={organizations}
          renderInput={params => <TextField {...params} label={t('tutelles.tutelle')} />}
          onChange={onOrganizationChange}
          value={organization}
        />
      </Box>
      <Box mb={1}>
        <TextField label={t('tutelles.code')} onChange={onCodeChange} value={code ?? ''} />
      </Box>
      <Box textAlign="right">
        <Button onClick={onClick} disabled={disabled}>
          {btnLabel}
        </Button>
      </Box>
    </Box>
  );
};

export default TutelleForm;

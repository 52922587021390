import './ribbon.scss';

const mode = import.meta.env.MODE;
const version = import.meta.env.PACKAGE_VERSION;

const Ribbon = () => {
  if (mode === 'production') {
    return null;
  }

  return (
    <div className="ribbon">
      {mode} {version}
    </div>
  );
};

export default Ribbon;

interface SpecialCharacter {
  normal: string;
  capital?: string;
}

export const greek: SpecialCharacter[] = [];

for (let i = 913; i <= 937; i++) {
  const j = i + 32;
  const item = {
    capital: '&#' + i + ';',
    normal: '&#' + j + ';',
  };
  greek.push(item);
}

const mathEntities = [
  '&#8704;',
  '&#8706;',
  '&#8707;',
  '&#8709;',
  '&#8711;',
  '&#8712;',
  '&#8713;',
  '&#8715;',
  '&#8719;',
  '&#8721;',
  '&#8722;',
  '&#8727;',
  '&#8730;',
  '&#8733;',
  '&#8734;',
  '&#8736;',
  '&#8743;',
  '&#8744;',
  '&#8745;',
  '&#8746;',
  '&#8747;',
  '&#8756;',
  '&#8764;',
  '&#8773;',
  '&#8776;',
  '&#8800;',
  '&#8801;',
  '&#8804;',
  '&#8805;',
  '&#8834;',
  '&#8835;',
  '&#8836;',
  '&#8838;',
  '&#8839;',
  '&#8853;',
  '&#8855;',
  '&#8869;',
  '&#8901;',
  '&#215;',
  '&#247;',
  '&#402;',
];

export const maths: SpecialCharacter[] = mathEntities.map(entity => ({
  normal: entity,
}));

const specialEntities = [
  '&#176;',
  '&#177;',
  '&#178;',
  '&#179;',
  '&#180;',
  '&#181;',
  '&#182;',
  '&#183;',
  '&#184;',
  '&#185;',
  '&#186;',
  '&#187;',
  '&#188;',
  '&#189;',
  '&#190;',
  '&#191;',
  '&#8211;',
  '&#8212;',
  '&#8216;',
  '&#8217;',
  '&#8218;',
  '&#8220;',
  '&#8221;',
  '&#8222;',
  '&#8224;',
  '&#8225;',
  '&#8226;',
  '&#8230;',
  '&#8240;',
  '&#8242;',
  '&#8243;',
  '&#8254;',
  '&#8260;',
  '&#8364;',
  '&#8472;',
  '&#8465;',
  '&#8476;',
  '&#8482;',
  '&#8501;',
  '&#8592;',
  '&#8594;',
  '&#8595;',
  '&#8596;',
  '&#8629;',
  '&#8656;',
  '&#8657;',
  '&#8658;',
  '&#8659;',
  '&#8660;',
];

export const specials: SpecialCharacter[] = specialEntities.map(entity => ({
  normal: entity,
}));

export const latin: SpecialCharacter[] = [];

for (let i = 192; i <= 255; i++) {
  if (i !== 215 && i !== 247) {
    const item = '&#' + i + ';';
    latin.push({
      normal: item,
    });
  }
}

import { RouteObject } from 'react-router-dom';
import HomePage from './HomePage';

const routes: RouteObject[] = [
  {
    path: '',
    index: true,
    element: <HomePage />,
  },
];

export default routes;

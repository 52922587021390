import { QueryDslQueryContainer } from '@elastic/elasticsearch/lib/api/types';
import getAggs from 'modules/search/config/facets';
import { useGetUserSearchsQuery } from 'services/endpoints/UserSearchEndpoints';
import { useSearchQuery } from 'services/searchApi';
import { useCurrentUserId } from 'shared/hooks/auth-hook';
import { useAppSelector } from 'shared/redux/redux-utils';
import { EscapeSearch } from 'shared/types/EscapeSearch.model';

export const useMakeSearchQuery = () => {
  const query = useAppSelector(state => state.search.query);
  const indexFilter = useAppSelector(state => state.search.indexFilter);
  const from = useAppSelector(state => state.search.from);
  const size = useAppSelector(state => state.search.size);

  const searchQuery: EscapeSearch = {
    index: indexFilter,
    search: {
      query: query as QueryDslQueryContainer,
      from,
      size,
      aggs: getAggs(indexFilter),
    },
  };

  return useSearchQuery(searchQuery, {
    refetchOnMountOrArgChange: true,
    skip: !query,
  });
};

export const useGetLastSearches = (limit = 1) => {
  const userId = useCurrentUserId();
  return useGetUserSearchsQuery(
    {
      limit,
      filter: {
        user: userId,
      },
      sort: ['-date_created'],
    },
    {
      skip: !userId,
    },
  );
};

export const useGetSearchText = () => {
  const query = useAppSelector(state => state.search.query);
  const must = query?.bool?.must ?? [];
  return Array.isArray(must) ? must[0]?.query_string?.query : undefined;
};

export const useGetSearchFacets = () => {
  const query = useAppSelector(state => state.search.query);
  const must = query?.bool?.must ?? [];
  return Array.isArray(must) ? must.filter(item => item.terms) : [];
};
